import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";

const AddPropertyForm = ({ onSave }) => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        propertyType: undefined,
        code: undefined,
        name: undefined,

        street: undefined,
        city: undefined,
        state: undefined,
        country: "US",
        zipCode: undefined,

        bedrooms: undefined,
        bathrooms: undefined,
        sleeps: undefined,
        sqft: undefined,
    });

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            try {
                setLoading(true);
                onSave && onSave(formData);
            } catch (error) {
                toast.error(`Cannot save property: ${error.message}`, { theme: "colored" });
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        setValidated(true);
    };
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={6} >
                    <Form.Label>Code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Property Code"
                        value={formData.code}
                        name="code"
                        onChange={handleChange}
                        isInvalid={formData.code === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the property's internal code
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={6}  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Friendly name for the property"
                        value={formData.name}
                        name="name"
                        onChange={handleChange}
                        isInvalid={formData.name === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add a name for the property
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={6} >
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                        placeholder="Select the property type"
                        value={formData.propertyType}
                        name="propertyType"
                        onChange={handleChange}
                        isInvalid={formData.propertyType === ""}
                        required
                    >
                        <option value=""></option>
                        <option value="HOME">Home</option>
                        <option value="ROOM">Room</option>
                        <option value="CONDO">Condo</option>
                        <option value="BUILDING">Building</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Select the type of property
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={6}  >
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Street"
                        value={formData.street}
                        name="street"
                        onChange={handleChange}
                        isInvalid={formData.street === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the street address
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={4} >
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Zip code"
                        value={formData.zipCode}
                        name="zipCode"
                        onChange={handleChange}
                        isInvalid={formData.zipCode === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the property's zip code
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={4} >
                    <Form.Label>State</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="State"
                        value={formData.state}
                        name="state"
                        onChange={handleChange}
                        isInvalid={formData.state === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the property's state
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={4} >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="City"
                        value={formData.city}
                        name="city"
                        onChange={handleChange}
                        isInvalid={formData.city === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the property's city
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={3}  >
                    <Form.Label>Bedrooms</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="# of bedrooms"
                        value={formData.bedrooms}
                        name="bedrooms"
                        onChange={handleChange}
                        isInvalid={formData.bedrooms === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the number of bedrooms
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={3} >
                    <Form.Label>Bathrooms</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="# of bathrooms"
                        value={formData.bathrooms}
                        name="bathrooms"
                        onChange={handleChange}
                        isInvalid={formData.bathrooms === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the number of bathrooms
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={3} >
                    <Form.Label>Occupants</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="# of sleeps"
                        value={formData.sleeps}
                        name="sleeps"
                        onChange={handleChange}
                        isInvalid={formData.sleeps === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the number of sleeps
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={3} >
                    <Form.Label>Size (sqft)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Size (in sqft)"
                        value={formData.sqft}
                        name="sqft"
                        onChange={handleChange}
                        isInvalid={formData.sqft === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the size of the property
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="my-1 g-3">
                <div className="text-end">
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? '...' : 'Submit'}
                    </Button>
                </div>
            </Row>
        </Form>
    )
}

export default AddPropertyForm;