import React, { useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AuditTableHeader from './AuditTableHeader';
import { formatDate } from 'utils/TimeUtils';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';

const AuditTracesList = ({ audit, columns, pagination }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTrace, setSelectedTrace] = useState({});
  const { currentPage, maxResults, pageSize, onChangePage } = pagination;
  const defaultColumns = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'userId',
      Header: 'User ID',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'method',
      Header: 'Action',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'startedOn',
      Header: 'Executed On',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { startedOn } = rowData.row.original;
        return <span>{formatDate(startedOn)}</span>;
      }
    },
    {
      accessor: 'endedOn',
      Header: 'Finished On',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { endedOn } = rowData.row.original;
        return <span>{formatDate(endedOn)}</span>;
      }
    },
    {
      accessor: 'duration',
      Header: 'Duration (ms)',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'result',
      Header: 'Result',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { result } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: result === 'OK',
              danger: result === 'ERROR'
            })}
            className="px-3"
          >
            {result}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return (
          <Button
            variant="falcon-default"
            size="sm"
            transform="shrink-3"
            className="mx-2"
            onClick={() => {
              setShowModal(true);
              setSelectedTrace(rowData.row.original);
            }
            }
          >
            <span className="d-none d-sm-inline-block">Outcome</span>
          </Button>);
      }
    }
  ];
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Outcome of trace #{selectedTrace.id}  <SoftBadge
            pill
            bg={classNames({
              success: selectedTrace.result === 'OK',
              danger: selectedTrace.result === 'ERROR'
            })}
            className="px-3"
          >
            {selectedTrace.result}
          </SoftBadge></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea rows={10} disabled className='form-control' value={selectedTrace.outcome} />
        </Modal.Body>
      </Modal>

      <AdvanceTableWrapper
        columns={columns || defaultColumns}
        data={audit}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <AuditTableHeader table />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              canPreviousPage={currentPage !== 0}
              canNextPage={currentPage !== Math.floor(maxResults / pageSize)}
              previousPage={() => onChangePage(currentPage - 1)}
              nextPage={() => onChangePage(currentPage + 1)}
              pageCount={Math.ceil(maxResults / pageSize)}
              pageIndex={currentPage}
              gotoPage={(page) => onChangePage(page)}
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default AuditTracesList