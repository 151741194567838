import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';


const ConfirmModal = ({
  isOpen,
  setIsOpen,
  confirm,
  header,
  body,
  confirmButtonText,
}) => {

  return (
    <Modal
      show={isOpen}
      onHide={setIsOpen}
    >
      <ModalHeader >
        {header}
      </ModalHeader>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter>
        <Button variant="light" onClick={setIsOpen} size='sm'>Close</Button>
        <Button variant="primary" onClick={confirm} size='sm'>{confirmButtonText || "OK"}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModal