import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import classNames from 'classnames';

const MiniStatisticsCard = ({ stat, children }) => {
  const { title, value, decimal, prefix, suffix, valueClassName } = stat;
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="flex-between-center">
          <Col className="d-md-flex d-lg-block flex-between-center">
            <h6 className="mb-md-0 mb-lg-0">{title}</h6>
            {children}
          </Col>
          <Col xs="auto">
            <h4 className={classNames(valueClassName, 'fs-3 fw-normal')}>
              {/* TODO: move the count duration into dashboard/index.js */}
              <CountUp
                start={0}
                end={value}
                duration={1}
                prefix={prefix}
                suffix={suffix}
                decimals={decimal ? 2 : 0}
                decimal="."
              />
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

MiniStatisticsCard.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    decimal: PropTypes.bool,
    prefix: PropTypes.string.isRequired,
    suffix: PropTypes.string.isRequired,
    valueClassName: PropTypes.string
  }),
  children: PropTypes.node
};

export default MiniStatisticsCard;
