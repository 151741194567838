/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Portfolio from '../../components/dashboards/default/Portfolio';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/auth/AuthContext';
import PortfolioList from 'components/portfolio/PortfolioList';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import StatisticsCard from 'components/dashboards/vacaygreen-pm/StatisticsCard';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import PortfolioPerformance from 'components/portfolio/PortfolioPerformance';

const getReservationStats = (count) => ({
  title: 'Booked Reservations',
  value: count,
  hideSubtitle: true,
  decimal: false,
  prefix: '',
  suffix: '',
  valueClassName: 'text-warning',
  image: bg3
})

const PMPortfolio = () => {
  const [loading, setLoading] = useState(false);
  const vacaygreen = useVacaygreen();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [offsetDonut, setOffsetDonut] = useState([]);
  const [reservationsStats, setReservationStats] = useState({});

  const getMetrics = async () => {
    setLoading(true);
    const response = await vacaygreen.pms.getPortfolioMetrics(user.mainAccount.id);
    if (response.ok) {
      const paginationResponse = await response.json();
      const chartSeries = [
        { id: 1, value: paginationResponse.offsetEnabledProperties, name: 'Offset', color: 'primary' },
        { id: 2, value: paginationResponse.offsetDisabledProperties, name: 'Non-Offset', color: 'info' },
        { id: 3, value: paginationResponse.offsetSuspendedProperties, name: 'Suspended', color: 'warn' },
      ];
      setOffsetDonut(chartSeries);
      setReservationStats(getReservationStats(paginationResponse.reservations))
    }
    setLoading(false);
  }

  const getEntryPoint = () => {
    try {
      const { mainAccount: { onboardingState } } = user
      if (onboardingState !== "DONE")
        navigate("/onboarding")
      else
        return
    } catch (e) {
      return navigate("/authentication/login")
    }
  }

  useEffect(() => {
    getEntryPoint();
    getMetrics();
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <Portfolio loading={loading} data={offsetDonut} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={3}>
          <StatisticsCard
            loading={loading}
            stat={reservationsStats}
            style={{ minWidth: '12rem', minHeight: '9.2rem' }}
          />
        </Col>
      </Row>

      <Card>
        <Row className="g-3 mb-3">
          <Col lg={12}>
            <Tabs defaultActiveKey="list" id="uncontrolled-tab-example">
              <Tab eventKey="list" title="List" className=''>
                <PortfolioList />
              </Tab>
              <Tab eventKey="performance" title="Performance" className='' mountOnEnter={true}>
                <PortfolioPerformance />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default PMPortfolio;
