import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from "react-toastify";
import FalconDropzone from "components/common/FalconDropzone";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";

const PortfolioImportForm = ({ onSave }) => {
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false)
	const [bulkFile, setBulkFile] = useState();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (!form.checkValidity()) {
			e.stopPropagation();
		} else {
			try {
				setLoading(true);
				onSave && onSave(bulkFile);
			} catch (error) {
				toast.error(`Cannot upload portfolio: ${error.message}`, { theme: "colored" });
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
		setValidated(true);
	};
	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
			<Row className="mb-1 g-3">
				<Col md>
					{!bulkFile ? (
						<FalconDropzone
							files={bulkFile}
							onChange={files => {
								setBulkFile(files[0]);
							}}
							multiple={false}
							accept="application/xslx"
							placeholder={
								<>
									<Flex justifyContent="center">
										<img src={cloudUpload} alt="" width={25} className="me-2" />
										<p className="fs-0 mb-0 text-700">
											Upload your portfolio in XSLX format
										</p>
									</Flex>
									<p className="mb-0 w-75 mx-auto text-400">
										You can download the required template from <a href={`${process.env.PUBLIC_URL}/Portfolio_template.xlsx`}> here</a>
									</p>
								</>
							}
						/>
					) : (
						<Flex justifyContent="space-between">
							<p className="fs-0 mb-0 text-700">
								{bulkFile.path}
							</p>
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="trash"
								transform="shrink-3"
								className="mx-2"
								onClick={() => setBulkFile(null)}
							/>
						</Flex>
					)}
				</Col>
			</Row>

			<Row className="my-1 g-3">
				<div className="text-end">
					<Button variant="primary" type="submit" disabled={loading || !bulkFile}>
						{loading ? '...' : 'Upload'}
					</Button>
				</div>
			</Row>
		</Form>
	)
}

export default PortfolioImportForm;