export const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("account_id")
    localStorage.removeItem("user")
    localStorage.removeItem("selected_property")
    localStorage.removeItem("current_user")
    localStorage.removeItem("ticket")
    localStorage.removeItem("isNavbarVerticalCollapsed")
    
    console.log("User logged out")
  }