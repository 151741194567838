import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvoiceInfo from './InvoiceInfo';
import InvoiceLineItems from './InvoiceLineItems';
import SoftBadge from 'components/common/SoftBadge';
import { formatDate } from 'utils/TimeUtils';

const InvoiceDetails = ({ invoice }) => {
  return (
    <>
      {invoice &&
        <>
          <PageHeader title={`Invoice #${invoice.id}`} titleTag="h5" className="mb-3">
            <p className="fs--1 mt-1">Issued on {formatDate(invoice.createdOn)}</p>
            <div>
              <strong className="me-2">Status: </strong>
              <SoftBadge pill bg="success" className="fs--2">
                {invoice.invoiceStatus}
                <FontAwesomeIcon
                  icon="check"
                  className="ms-1"
                  transform="shrink-2"
                />
              </SoftBadge>
            </div>
          </PageHeader>
          {invoice.billingAddress && <InvoiceInfo addressInfo={invoice.billingAddress} />}
          {invoice.lineItems && <InvoiceLineItems lineItems={invoice.lineItems} total={invoice.total} />}
        </>
      }
    </>
  );
};

export default InvoiceDetails;
