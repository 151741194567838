import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import CountUp from 'react-countup';

import Background from 'components/common/Background';
import Loader from 'components/common/Loader';

const StatisticsCard = ({ stat, loading, ...rest }) => {
  const {
    title,
    value,
    decimal,
    prefix,
    suffix,
    subTitleValue,
    subTitlePrefix,
    subTitleSuffix,
    valueClassName,
    image,
    className,
    hideSubtitle
  } = stat;
  return (
    <Card className={classNames(className, 'overflow-hidden')} {...rest}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative">
        <h6>{title}</h6>
        {loading ? <Loader /> : <div>
          <div
            className={classNames(
              valueClassName,
              'display-4 fs-4 mb-2 fw-normal font-sans-serif'
            )}
          >
            {/* TODO: move the count duration into dashboard/index.js */}
            <CountUp
              start={0}
              end={value}
              duration={1}
              prefix={prefix}
              suffix={suffix}
              separator=","
              decimals={decimal ? 2 : 0}
              decimal="."
            />
          </div>
          {!hideSubtitle && <p className="fs--2 mb-0 text-500">
            <CountUp
              start={0}
              end={subTitleValue}
              duration={1}
              prefix={subTitlePrefix}
              suffix={subTitleSuffix}
              separator=","
              decimals={decimal ? 2 : 0}
              decimal="."
            />
          </p>}
        </div>
        }
      </Card.Body>
    </Card>
  );
};

StatisticsCard.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    subTitleValue: PropTypes.number,
    decimal: PropTypes.bool,
    prefix: PropTypes.string.isRequired,
    suffix: PropTypes.string.isRequired,
    subTitlePrefix: PropTypes.string,
    subTitleSuffix: PropTypes.string,
    valueClassName: PropTypes.string,
    image: PropTypes.string,
    className: PropTypes.string
  })
};

export default StatisticsCard;
