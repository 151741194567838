import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart, BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs';
import { Card } from 'react-bootstrap';

import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { getColor } from 'helpers/utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  BarChart
]);

const LABEL_FONT_WEIGHT = 'lighter';

const tooltipFormatter = params => {
  return `<div class="card">
              <div class="card-header bg-300 py-2">
                <h6 class="text-600 mb-0">${params[0]?.axisValue}</h6>
              </div>
            <div class="card-body py-2">
              <h6 class="text-600 mb-0 fw-normal">
                <span class="dot me-1 d-inline-block bg-300"></span>
                Carbon Offset Cost: 
                <span class="fw-medium">$${params[3]?.data.value}</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-success"></span>
                Reservation Night Rate: 
                <span class="fw-medium">$${params[0]?.data}</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-danger"></span>
                Estimated Occupancy Rate: 
                <span class="fw-medium">${params[1]?.data}%</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-info"></span>
                Target Margin: 
                <span class="fw-medium">${params[2]?.data}%</span>
              </h6>
            </div>
          </div>`;
};

const getBarChartData = barChartData => {
  return barChartData.map(dataPoint => ({
    value: getFormattedCurrency(dataPoint),
    itemStyle: {
      borderRadius: [4, 4, 0, 0],
      color: getColor('300')
    }
  }));
};

// Unfortunately, eCharts doesn't choose y-axis splits that align on the left and right axes. So we have to do it ourselves.
const getReservationNightRateAxisMax = reservationNightRatesData => {
  const maxResNightRate = Math.max(...reservationNightRatesData);
  const roundedResNightRate = Math.ceil(maxResNightRate / 5) * 5;
  return getFormattedCurrency(roundedResNightRate);
};

const getFormattedCurrencyYAxisValues = value => {
  const formattedValue = getFormattedCurrency(value);
  return '$' + formattedValue;
};

const getFormattedCurrency = number => {
  if (number === null) {
    return null;
  }

  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  });
  return formatter.format(number);
};

const getOptions = (
  datesData,
  reservationNightRatesData,
  estimatedOccupancyRatesData,
  targetMarginsData,
  carbonOffsetCostData
) => ({
  legend: {
    left: 'right',
    data: [
      'Carbon Offset Cost',
      'Reservation Night Rate',
      'Estimated Occupancy Rate',
      'Target Margin'
    ],
    textStyle: { color: getColor('600') }
  },
  axisPointer: {
    show: true
  },
  tooltip: {
    trigger: 'axis',
    padding: 0,
    backgroundColor: 'transparent',
    borderWidth: 0,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  xAxis: {
    type: 'category',
    data: datesData.map(date => dayjs(date).format('MMM')),
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: { color: getColor('600') },
    boundaryGap: true
  },
  yAxis: [
    // Left y-axis: percentages
    {
      type: 'value',
      min: 0,
      max: 100,
      splitNumber: 10,
      axisLabel: {
        show: true,
        color: getColor('400'),
        formatter: '{value}%'
      },
      splitLine: {
        show: true,
        lineStyle: { color: getColor('200') }
      }
    },
    // Right y-axis: $
    {
      type: 'value',
      max: getReservationNightRateAxisMax(reservationNightRatesData),
      splitNumber: 10,
      axisLabel: {
        show: true,
        color: getColor('400'),
        formatter: getFormattedCurrencyYAxisValues
      },
      splitLine: {
        show: true,
        lineStyle: { color: getColor('200') }
      }
    },
    // Right y-axis: hidden carbon offset
    {
      type: 'value',
      show: false,
      max: 1.05 * Math.max(...carbonOffsetCostData) // Add 5% margin from top of graph
    }
  ],
  series: [
    {
      name: 'Reservation Night Rate',
      data: reservationNightRatesData.map(number =>
        getFormattedCurrency(number)
      ),
      yAxisIndex: 1,
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('success'),
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('success'),
        borderWidth: 2
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '${c}',
        color: getColor('success')
      }
    },
    {
      name: 'Estimated Occupancy Rate',
      data: estimatedOccupancyRatesData,
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('danger'),
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('danger'),
        borderWidth: 2
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '{c}%',
        color: getColor('danger')
      }
    },
    {
      name: 'Target Margin',
      data: targetMarginsData,
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('info'),
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('info'),
        borderWidth: 2
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '{c}%',
        color: getColor('info')
      }
    },
    {
      name: 'Carbon Offset Cost',
      yAxisIndex: 2,
      data: getBarChartData(carbonOffsetCostData),
      type: 'bar',
      itemStyle: {
        color: getColor('300')
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        color: getColor('600'),
        formatter: '${c}'
      },
      emphasis: {
        itemStyle: {
          color: getColor('400'),
          opacity: 1
        }
      }
    }
  ],
  grid: { right: '5%', left: '5%', bottom: '5%', top: '5%' }
});

const ReservationNightRateChart = ({ chartData }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Reservation Night Rate"
        titleTag="h3"
        className="pb-0"
        endEl={
          <Flex>
            {/* Export Button */}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
            </IconButton>
          </Flex>
        }
      />
      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(
            chartData.dates,
            chartData.reservationNightRates,
            chartData.estimatedOccupancyRates,
            chartData.targetMargins,
            chartData.carbonOffsetCost
          )}
          style={{ height: '40rem' }}
        />
      </Card.Body>
    </Card>
  );
};

ReservationNightRateChart.propTypes = {
  chartData: PropTypes.shape({
    dates: PropTypes.arrayOf(PropTypes.string),
    reservationNightRates: PropTypes.arrayOf(PropTypes.number),
    estimatedOccupancyRates: PropTypes.arrayOf(PropTypes.number),
    targetMargins: PropTypes.arrayOf(PropTypes.number),
    carbonOffsetCost: PropTypes.arrayOf(PropTypes.number)
  })
};

export default ReservationNightRateChart;
