import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import CountUp from 'react-countup';

import Background from 'components/common/Background';
import Loader from 'components/common/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatisticsGridCard = ({ data, loading, ...rest }) => {
  const {
    title,
    image,
    className,
  } = rest;
  return (
    <Card className={classNames(className, 'overflow-hidden')} {...rest}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative">
        <Row>
          <Col>
            <h3 className="mb-3 text-nowrap py-2 py-xl-0 text-center">{title}</h3>
          </Col>
        </Row>
        {loading ? <Loader /> :
          <Row className="g-0">{
            data.map(stat => (
              <Col
                className={classNames(
                  stat.valueClassName,
                  'display-4 fs-4 mb-2 fw-normal font-sans-serif'
                )}
              >
                <h6>{stat.title}</h6>
                <CountUp
                  start={0}
                  end={stat.amount}
                  duration={1}
                  prefix={stat.prefix || ''}
                  suffix={stat.suffix || ''}
                  separator=","
                  decimals={2}
                  decimal="."
                />
                {stat.icon && (
                  <FontAwesomeIcon
                    icon={stat.icon}
                    transform="shrink-2"
                    className="ms-3"
                  />
                )}

              </Col>))
          }
          </Row>
        }
      </Card.Body>
    </Card >
  );
};

export default StatisticsGridCard;
