import React, { useContext, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';

import AppContext from 'context/Context';
import { navbarBreakPoint } from 'config';

import AccountDropDown from './AccountDropDown';
import Logo from 'components/common/Logo';
import { AccountDropDownItems } from 'helpers/utils';

const VacayGreenPmTopNavbar = ({hideAccountOptions}) => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames('navbar-glass  fs--1 navbar-top sticky-kit', {
        'navbar-glass-shadow': showDropShadow
      })}
    >
      <Logo at="navbar-vertical" preventRedicretion logoImageWidth={15} logoTextWidth={120} />
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <AccountDropDown menuItems={hideAccountOptions ? [] : AccountDropDownItems} />
    </Navbar>
  );
};

export default VacayGreenPmTopNavbar;
