import { authApiFetch } from '../../Vacaygreen'

const VacaygreenAdmin = {

    getAuditTraces: async (page, pageSize) => {
        return authApiFetch(`admin/audit?page=${page || 0}&pageSize=${pageSize || 50}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getAccounts: async (page, pageSize) => {
        return authApiFetch(`admin/accounts?page=${page || 0}&pageSize=${pageSize || 50}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getAccount: async (id) => {
        return authApiFetch(`admin/accounts/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    postPurchase: async (purchase) => {
        return authApiFetch(`admin/transactions/purchase`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(purchase)
        });
    },

    getTransactions: async (page, pageSize) => {
        return authApiFetch(`admin/transactions?page=${page || 0}&pageSize=${pageSize || 50}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getAllocations: async (page, pageSize) => {
        return authApiFetch(`admin/transactions/allocations?page=${page || 0}&pageSize=${pageSize || 50}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getInvoices: async (page, pageSize) => {
        return authApiFetch(`admin/invoices?page=${page || 0}&pageSize=${pageSize || 50}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getInvoice: async (id) => {
        return authApiFetch(`admin/invoices/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    updateInvoiceStatus: async (id, status) => {
        return authApiFetch(`admin/invoices/${id}/${status}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getMetrics: async () => {
        return authApiFetch(`admin/inventory/metrics`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    updateMetrics: async () => {
        return authApiFetch(`admin/inventory/metrics`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getProperties: async () => {
        return authApiFetch(`admin/properties`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
}

export default VacaygreenAdmin;