import VacaygreenAuth from "./modules/auth";
import { logout } from "../../context/auth/utils";
import VacaygreenOnboarding from "./modules/onboarding";
import VacaygreenSync from "./modules/sync";
import VacaygreenPMS from "./modules/pms";
import VacaygreenAdmin from "./modules/admin";
import VacaygreenAccount from "./modules/account";
import VacaygreenProperties from "./modules/properties";


//BH Backend
//export const BASE_URL = process.env.REACT_APP_API_BASE || "https://api.vacaygreen.com";
export const BASE_URL = process.env.REACT_APP_API_BASE || "http://localhost:8080";
export const API_BASE_URL = BASE_URL + "/api/v1";

export const MOCK_MODE = true

export const getAuthToken = () => {
    return localStorage.token;
};

export const authApiUpload = (url, options) => {
    const extendedOptions = { ...options };
    if (getAuthToken()) {
        extendedOptions.headers = {
            ...extendedOptions.headers,
            'X-API-KEY': getAuthToken(),
        };
    }
    return fetch(API_BASE_URL + "/" + url, extendedOptions);
}

export const authApiFetch = async (url, options, isDelete) => {
    const extendedOptions = {
        ...options
    };
    if (getAuthToken()) {
        extendedOptions.headers = {
            ...extendedOptions.headers,
            'X-API-KEY': getAuthToken(),
            'Content-Type': !isDelete && 'application/json'
        };
    }

    const response = await fetch(API_BASE_URL + "/" + url, extendedOptions)
    if (response.status === 401) {
        logout()
        return window.location.href = window.location.origin + '/authentication/session-expired'
    }
    return response
};

export const apiFetch = (url, options) => {
    return fetch(API_BASE_URL + "/" + url, options);
};

export const useVacaygreen = () => {

    /* API modules */
    return {
        auth: VacaygreenAuth,
        onboarding: VacaygreenOnboarding,
        sync: VacaygreenSync,
        pms: VacaygreenPMS,
        admin: VacaygreenAdmin,
        account: VacaygreenAccount,
        properties: VacaygreenProperties
    };
};
