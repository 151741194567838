import React, {useContext} from "react";
import {Navigate, Outlet, Route} from "react-router-dom";
import { AuthContext } from '../../context/auth/AuthContext'

const PrivateRoute = ({
    user,
    redirectPath = '/authentication/login',
    children,
}) => {
    const { token } = useContext(AuthContext);
    if (!token) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default PrivateRoute;