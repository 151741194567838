import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useVacaygreen } from "services/vacaygreen/Vacaygreen";

const CreatePurchaseForm = ({ onSave }) => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const vacaygreen = useVacaygreen();

    const [formData, setFormData] = useState({
        volume: undefined,
        vendor: undefined,
        project: undefined,
        cost: undefined,
        orderReference: undefined
    });

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            try {
                setLoading(true);
                await vacaygreen.admin.postPurchase(formData);
                onSave && onSave();
            } catch (error) {
                toast.error(`Cannot save transaction: ${error.message}`);
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        setValidated(true);
    };
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={6} >
                    <Form.Label>Volume</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Tons"
                        value={formData.volume}
                        name="volume"
                        onChange={handleChange}
                        isInvalid={formData.volume === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the volume of the purchase
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={6} >
                    <Form.Label>Cost</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Total amount of the purchase"
                        value={formData.cost}
                        name="cost"
                        onChange={handleChange}
                        isInvalid={formData.cost === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the cost of the purchase
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={6} >
                    <Form.Label>Vendor</Form.Label>
                    <Form.Select
                        placeholder="Select the Vendor"
                        value={formData.vendor}
                        name="vendor"
                        onChange={handleChange}
                        isInvalid={formData.vendor === ""}
                        required
                    >
                        <option value={null}></option>
                        <option value="PATCH">Patch</option>
                        <option value="CLOVERLY">Cloverly</option>
                        <option value="TERRAPASS">TerraPass</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Select the vendor of the purchase
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={6}  >
                    <Form.Label>Project</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the destination project of this purchase"
                        value={formData.project}
                        name="project"
                        onChange={handleChange}
                    />
                </Form.Group>
            </Row>

            <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={6}  >
                    <Form.Label>Order Reference</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Order Reference"
                        value={formData.orderReference}
                        name="orderReference"
                        onChange={handleChange}
                        isInvalid={formData.orderReference === ""}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Add the reference to the original order
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-1 g-3">
                <div className="text-end">
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? '...' : 'Submit'}
                    </Button>
                </div>
            </Row>
        </Form>
    )
}

export default CreatePurchaseForm;