import React from 'react';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
  return (
    <AuthCardLayout
    leftSideContent={
      <p className="pt-3 text-primary">
        <Link className="fs--1 text-600" to="/authentication/login">
          <span className="d-inline-block ms-1"> &larr;</span> Back to log in
        </Link>
      </p>
    }
    footer={false}>
      <h4 className="mb-0"> Forgot your password?</h4>
      <p className="mb-0">Enter your email and we'll send you a reset link.</p>
      <ForgetPasswordForm layout="card" />
    </AuthCardLayout>
  );
};

export default ForgetPassword;
