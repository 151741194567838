import { Card, Col, Row } from "react-bootstrap";
import { formatDate } from "utils/TimeUtils";
import IconButton from "./IconButton";

const LastUpdated = ({lastUpdate, onRefresh}) => {
    return (
        <Card className="bg-light my-3">
            <Card.Body className="p-3">
                <Row className="flex-between-center">
                    <Col md>
                        <p className="fs--1 mb-0">
                            Last update at {formatDate(lastUpdate)}
                        </p>
                    </Col>
                    <Col xs="auto">
                        <IconButton
                            size="sm"
                            iconAlign="right"
                            variant="falcon-default"
                            icon="undo-alt"
                            className="me-2"
                            onClick={() => onRefresh && onRefresh()}
                        >
                            Refresh
                        </IconButton>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default LastUpdated;