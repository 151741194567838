import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PageHeader from 'components/common/PageHeader';
import FalconEditor from 'components/common/FalconEditor';

const serveCode = `npm install -g serve
serve -s build`;

const GettingStarted = () => (
  <>
    <PageHeader title="Getting Started" className="mb-3">
      <p className="mt-2 mb-0">
        Welcome to the VacayGreen. This doc will guide you to understand how{' '}
        <strong>Falcon-React</strong> theme is organized, basics of how to
        customize, and how to compile from the source code if you want.
      </p>
    </PageHeader>

    <Card className="mb-3">
      <FalconCardHeader title="Running in Local environment" />
      <Card.Body>
        <p>
          This project is scaffolded using{' '}
          <a
            href="https://create-react-app.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            Create React App
          </a>
          .
        </p>
        <ol className="mb-0 ps-card">
          <li>
            Install{' '}
            <a
              href="https://nodejs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Node.js
            </a>{' '}
            if you do not already have it installed on your machine.
          </li>
          <li>Open the “falcon-react” directory with your cmd or terminal</li>
          <li>
            Run <code>npm i</code>
            <br />
            This command will download all the necessary dependencies for falcon
            in the <code>node_modules</code> directory.
          </li>
          <li>
            Run <code>npm start</code>. A local web server will start at{' '}
            <code>http://localhost:3000</code>
          </li>
        </ol>
      </Card.Body>
    </Card>
  </>
);

export default GettingStarted;
