import AuditTracesList from 'components/audit/AuditTracesList';
import Loader from 'components/common/Loader';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const AdminAudit = () => {
  const [loading, setLoading] = useState(false);
  const [maxResults, setMaxResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [auditTraces, setAuditTraces] = useState([]);
  const vacaygreen = useVacaygreen();

  const loadAudit = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.admin.getAuditTraces(currentPage, pageSize);
      const data = await response.json();
      setAuditTraces(data.results);
      setMaxResults(data.maxResults);
    } catch (error) {
      toast.error(`Something went wrong loading audit traces: ${error.message}`);
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    loadAudit();
  }, [currentPage])

  return (loading ? <Loader /> :
    <AuditTracesList
      pagination={
        {
          maxResults: maxResults,
          currentPage: currentPage,
          pageSize: pageSize,
          onChangePageSize: (pageSize) => setPageSize(pageSize),
          onChangePage: (page) => setCurrentPage(page),
        }
      }
      audit={auditTraces}
    />
  )
}

export default AdminAudit