import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { numberFormatter } from 'helpers/utils';

const Statistics = ({ data }) => {
  return (
    <>
      <Row className="mb-2">
        <Col xs={6} className="border-end border-200">
          <h4 className="mb-0">{numberFormatter(data.metrics?.volumePurchased)}</h4>
          <p className="fs--1 text-600 mb-0">Total Metric Tonnes</p>
        </Col>
        <Col xs={3} className="border-end text-center border-200">
          <h4 className="fs-0 mb-0">{data.metrics?.accounts}</h4>
          <p className="fs--1 text-600 mb-0">Cutomers</p>
        </Col>
        <Col className="text-center">
          <h4 className="fs-0 mb-0">{data.metrics?.properties}</h4>
          <p className="fs--1 text-600 mb-0">Properties</p>
        </Col>
      </Row>
    </>
  );
};

Statistics.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      variant: PropTypes.string.isRequired
    })
  )
};

export default Statistics;
