import AccountList from 'components/accounts/AccountList';
import Loader from 'components/common/Loader';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const AdminAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [maxResults, setMaxResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [accountList, setAccountList] = useState([]);
  const vacaygreen = useVacaygreen();

  const loadAccounts = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.admin.getAccounts(currentPage, pageSize);
      const data = await response.json();
      setAccountList(data.results);
      setMaxResults(data.maxResults);
    } catch (error) {
      toast.error(`Something went wrong loading your accounts: ${error.message}`);
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    loadAccounts();
  }, [currentPage])

  return (loading ? <Loader /> :
    <AccountList
      pagination={
        {
          maxResults: maxResults,
          currentPage: currentPage,
          pageSize: pageSize,
          onChangePageSize: (pageSize) => setPageSize(pageSize),
          onChangePage: (page) => setCurrentPage(page),
        }
      }
      accounts={accountList}
    />
  )
}

export default AdminAccounts