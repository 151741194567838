import React from 'react';
import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, Outlet } from 'react-router-dom';
import InvoiceTableHeader from './InvoicesTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { formatDate } from 'utils/TimeUtils';

const defaultColumns = [
  {
    accessor: 'id',
    Header: 'Invoice',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <>
          <Link to={`/invoices/${id}`}>
            <strong>#{id}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'createdOn',
    Header: 'Created On',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { createdOn } = rowData.row.original;
      return <span>{formatDate(createdOn)}</span>;
    }
  },
  {
    accessor: 'total',
    Header: 'Total Amount',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { total } = rowData.row.original;
      return <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</span>;
    }
  },
  {
    accessor: 'state',
    Header: 'Status',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { invoiceStatus } = rowData.row.original;
      return (
        <SoftBadge
          pill
          bg={classNames({
            success: invoiceStatus === 'PAID',
            warning: invoiceStatus === 'ISSUED',
            danger: invoiceStatus === 'OVERDUE',
            light: invoiceStatus === 'CANCELLED'
          })}
          className="px-3"
        >
          {invoiceStatus}
        </SoftBadge>
      );
    }
  },
];

const InvoicesList = ({ invoices, columns, pagination }) => {
  const { currentPage, maxResults, pageSize, onChangePage } = pagination;
  return (
    <>
      <Outlet />
      <AdvanceTableWrapper
        columns={columns || defaultColumns}
        data={invoices}
        pagination
        sortable
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <InvoiceTableHeader table />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              canPreviousPage={currentPage !== 0}
              canNextPage={currentPage !== Math.floor(maxResults / pageSize)}
              previousPage={() => onChangePage(currentPage - 1)}
              nextPage={() => onChangePage(currentPage + 1)}
              pageCount={Math.ceil(maxResults / pageSize)}
              pageIndex={currentPage}
              gotoPage={(page) => onChangePage(page)}
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default InvoicesList;
