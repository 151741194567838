import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { getColor } from 'helpers/utils';

const tableStyles = {
  primaryRow: {
    margin: 0,
    fontWeight: 'normal'
  },
  secondaryRow: {
    margin: 0,
    fontWeight: 'lighter'
  }
};

const monthOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const propertiesTableStatusBadge = [
  { content: 'Covered', type: 'success', icon: 'check' },
  { content: 'Not Covered', type: 'secondary', icon: 'ban' },
  { content: 'Suspended', type: 'disabled', icon: 'ban' }
];

const getActualOccupancyRateStyles = (estimatedRate, actualRate, styles) => {
  if (actualRate >= estimatedRate) {
    return {
      margin: styles.margin,
      fontWeight: styles.fontWeight,
      color: getColor('success')
    };
  } else {
    return {
      margin: styles.margin,
      fontWeight: styles.fontWeight,
      color: getColor('danger')
    };
  }
};

const columns = [
  {
    accessor: 'code',
    Header: 'ID',
    Cell: rowData => {
      return (
        <>
          <p style={tableStyles.primaryRow}>{rowData.row.original.code}</p>
        </>
      );
    }
  },
  {
    accessor: 'address',
    Header: 'Address',
    Cell: rowData => {
      return (
        <>
          <p style={tableStyles.primaryRow}>
            {rowData.row.original.name}
          </p>
          <p style={tableStyles.primaryRow}>
            {rowData.row.original.addressLine2}
          </p>
        </>
      );
    }
  },
  {
    accessor: 'monthlyEmissions',
    Header: 'Emissions',
    Cell: rowData => {
      return (
        <>
          <p style={tableStyles.primaryRow}>
            {rowData.row.original.emissionsByMonth} MTE
          </p>
        </>
      );
    }
  },
  {
    accessor: 'estimatedOccupancy',
    Header: 'Est. Reservations'
  },
  {
    accessor: 'offsetStatus',
    Header: 'Status',
    cellProps: {
      className: 'fs-0'
    },
    Cell: rowData => {
      const { offsetStatus } = rowData.row.original;
      const status = offsetStatus === "ENABLED" ? propertiesTableStatusBadge[0] : offsetStatus === "DISABLED" ? propertiesTableStatusBadge[1] : propertiesTableStatusBadge[2]
      return (
        <SoftBadge pill bg={status.type}>
          {status.content}
          <FontAwesomeIcon
            icon={status.icon}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    }
  }
];

const PropertiesTable = ({ tableData, perPage = 10 }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={tableData}
      sortable
      pagination
      perPage={perPage}
    >
      <Card className="h-100">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs="auto">
              <h3 className="mb-0 text-nowrap py-2 py-xl-0">Top Performing Properties</h3>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={tableData.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default PropertiesTable;
