export const orderList = [
  {
    id: '#180',
    name: 'Ricky Antony',
    email: 'ricky@example.com',
    date: '05/31/2022',
    period: 'May 2022',
    address: 'Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149',
    shippingType: 'Via Flat Rate',
    status: 'onhold',
    amount: 99,
    volume: 150.23
  },
  {
    id: '#181',
    name: 'Ricky Antony',
    email: 'ricky@example.com',
    date: '06/01/2019',
    period: 'May 2022',
    address: 'Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149',
    shippingType: 'Via Flat Rate',
    status: 'completed',
    amount: 99,
    volume: 150.23
  },
  {
    id: '#182',
    name: 'Kin Rossow',
    email: 'kin@example.com',
    date: '06/01/2022',
    period: 'June 2022',
    address: 'Kin Rossow, 1 Hollywood Blvd,Beverly Hills, California 90210',
    shippingType: 'Via Free Shipping',
    status: 'sequestered',
    amount: 120,
    volume: 86.21
  }
];
