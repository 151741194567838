export default [
  {
    version: '1.0.0',
    title: 'VacayGreen First Release!',
    badgeTitle: '!! yoohoo !!',
    publish: '28 March, 2022',
    logs: {
      Update: [
        "New onboarding process",
        "New PMS integration: <code>LMPM</code>"
      ],
      Fix: [
        'Minor bugs'
      ],

      Migration: {
        Steps: [
          'No steps necessary',
        ]
      }
    }
  },
  {
    version: '1.0.0-alpha1',
    title: 'initial release',
    publish: '12 August, 2021',
    children: 'Nothing to see here.'
  }
];
