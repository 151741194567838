import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { AuthContext } from 'context/auth/AuthContext';
import AppContext from 'context/Context';
import { OnboardingContext } from 'context/onboarding/OnboardingContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Form, Nav, ProgressBar, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import Success from '../wizard/Success';
import AccountInfoForm from './AccountInfoForm';
import DatasourceForm from './DatasourceForm';
import DataSyncForm from './DataSyncForm';

const OnboardingWizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const { account, user } = useContext(AuthContext);
  const vacaygreen = useVacaygreen();
  const { accountInfo, setAccountInfo, step, setStep, nextDisabled, backDisabled, setOnboardingStep } = useContext(OnboardingContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors
  } = useForm();
  const [pmss, setPmss] = useState([]);
  const [syncError, setSyncError] = useState(false);

  const getPMSProviders = async () => {
    try {
      const response = await vacaygreen.onboarding.getPMSProviders();
      if (response.ok) {
        const data = await response.json();
        setPmss(data);
      }
    } catch (error) {
      toast.error(`Something went wrong loading PMS configuration, try again later`, { theme: 'colored' });
    }
  }

  useEffect(() => {
    switch (user.mainAccount.onboardingState) {
      case "SET_PMS":
        getPMSProviders();
        setStep(2);
        break;
      case "FIRST_SYNC":
        setStep(3);
        break;
      case "DONE":
        setStep(4);
        break;
      default:
        setStep(1);
        break;
    }
  }, [user])

  useEffect(() => {
    reset({
      ...accountInfo
    })
  }, [accountInfo])

  const navItems = [
    {
      icon: 'user',
      label: 'Account',
      state: 'NEW'
    },
    {
      icon: 'file',
      label: 'Data Source',
      state: 'SET_PMS'
    },
    {
      icon: 'cloud-download-alt',
      label: 'sync',
      state: 'FIRST_SYNC'
    },
    {
      icon: 'thumbs-up',
      label: 'Done',
      state: 'DONE'
    }
  ];

  const onSubmitData = async data => {
    setSyncError(false);
    if (step === 1) {
      setAccountInfo({ ...accountInfo, ...data });
      await vacaygreen.onboarding.setAccountInfo(account, { ...data, logoBase64: data.logo && data.logo[0]?.base64 });
    }
    if (step === 2) {
      if (data.pms !== "NONE")
        await vacaygreen.onboarding.setPMS(account, data);
      else {
        setStep(4);
        await vacaygreen.onboarding.setOnboardingState(account, navItems[3].state);
        setOnboardingStep(navItems[3].state);
        return;
      }
    }
    setStep(step + 1);
    setOnboardingStep(navItems[step].state);
    await vacaygreen.onboarding.setOnboardingState(account, navItems[step].state);
  };

  const handleSyncCompleted = async () => {
    setStep(step + 1);
    await vacaygreen.onboarding.setOnboardingState(account, navItems[step].state);
    setOnboardingStep(navItems[step].state);
  };

  const handleSyncFailed = async () => {
    setStep(2);
    await vacaygreen.onboarding.setOnboardingState(account, navItems[1].state);
    setOnboardingStep(navItems[1].state);
    setSyncError(true);
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  return (
    <Row className='justify-content-center'>
      <Col xs={12} md={6}>
        <Card
          as={Form}
          noValidate
          onSubmit={handleSubmit(onSubmitData, onError)}
          className="theme-wizard mb-5 mt-5"
        >
          <Card.Header
            className={classNames('bg-light', {
              'px-4 py-3': variant === 'pills',
              'pb-2': !variant
            })}
          >
            <Nav className="justify-content-center" variant={variant}>
              {variant === 'pills'
                ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
                : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
            </Nav>
          </Card.Header>
          {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
          <Card.Body className="fw-normal px-md-6 py-4">
            {step === 1 && (
              <AccountInfoForm register={register} errors={errors} watch={watch} setValue={setValue} />
            )}
            {step === 2 && (
              <DatasourceForm
                register={register}
                errors={errors}
                watch={watch}
                pmss={pmss}
                syncError={syncError}
              />
            )}
            {step === 3 && (
              <DataSyncForm onSyncCompleted={handleSyncCompleted} onSyncFailed={handleSyncFailed} />
            )}
            {/*step === 4 && (
              <OffsetForm
                register={register}
                errors={errors}
                watch={watch}
              />
            )*/}
            {step === 4 && <Success reset={reset} />}
          </Card.Body>
          <Card.Footer
            className={classNames('px-md-6 bg-light', {
              'd-none': step === 4,
              ' d-flex': step < 5
            })}
          >
            <IconButton
              variant="link"
              icon={isRTL ? 'chevron-right' : 'chevron-left'}
              iconAlign="left"
              transform="down-1 shrink-4"
              className={classNames('px-0 fw-semi-bold', {
                'd-none': step === 1
              })}
              onClick={() => {
                setStep(step - 1);
              }}
              disabled={backDisabled}
            >
              Prev
            </IconButton>

            <IconButton
              variant="primary"
              className="ms-auto px-5"
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              disabled={nextDisabled}
            >
              Next
            </IconButton>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs && handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

OnboardingWizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default OnboardingWizardLayout;
