export const mapPropertyType = (propertyType) => {
    switch (propertyType) {
        case "HOME":
            return "Home";
        case "CONDO":
            return "Condo";
        case "BUILDING":
            return "Building";
        case "ROOM":
            return "Room";
        default:
            return propertyType;
    }
}

export const mapTransactionType = (type) => {
    switch (type) {
        case "PURCHASE":
            return "Purchase";
        case "ALLOCATION":
            return "Allocation";
        case "DEALLOCATION":
            return "Deallocation";
        default:
            return type;
    }
}