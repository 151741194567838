import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { accountInfo, billingInfo } from 'data/ecommerce/customerDetailsData';
import classNames from 'classnames';
import createMarkup from 'helpers/createMarkup';

const CustomerInfo = ({account}) => {

  const {holder} = account;

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Details</h5>
          </Col>
          <Col xs="auto">
            <IconButton
              iconClassName="fs--2 me-1"
              variant="falcon-default"
              size="sm"
              icon="pencil-alt"
            >
              Update details
            </IconButton>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light border-top">
        <Row>
          <Col lg xxl={5}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Account Information
            </h6>
            <Row key={account.id}>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">ID</p>
              </Col>
              <Col>
                <p>
                  {account.id}
                </p>
              </Col>
            </Row>
            <Row key={account.id}>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Created On</p>
              </Col>
              <Col>
                <p>
                  {account.createdOn}
                </p>
              </Col>
            </Row>
            <Row key={account.id}>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Status</p>
              </Col>
              <Col>
                <p>
                  {account.state}
                </p>
              </Col>
            </Row>
            <Row key={account.id}>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Activated On</p>
              </Col>
              <Col>
                <p>
                  {account.validatedOn}
                </p>
              </Col>
            </Row>
          </Col>
          {holder && <Col lg xxl={{ span: 5, offset: 1 }} className="mt-4 mt-lg-0">
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Holder Information
            </h6>
            <Row key={holder.email}>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Email</p>
              </Col>
              <Col>
                <p>
                  {holder.email}
                </p>
              </Col>
            </Row>
            <Row key={holder.email}>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Name</p>
              </Col>
              <Col>
                <p>
                  {holder.firstName} {holder.lastName}
                </p>
              </Col>
            </Row>
            <Row key={account.email}>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Address</p>
              </Col>
              <Col>
                {account.address ? <p>
                  {account.address.street}, {account.address.city}, {account.address.state} - {account.address.zipCode}
                </p> : 'Not set'}
              </Col>
            </Row>
          </Col>}
        </Row>
      </Card.Body>
      <Card.Footer className="border-top text-end">
        <IconButton
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="dollar-sign"
        >
          Refund
        </IconButton>
        <IconButton
          className="ms-2"
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="check"
        >
          Save changes
        </IconButton>
      </Card.Footer>
    </Card>
  );
};

export default CustomerInfo;
