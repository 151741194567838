import InventoryMetrics from 'components/dashboards/project-management/project-statistics/ProjectStatistics'
import React, { useEffect, useState } from 'react'
import user1 from 'assets/img/team/1.jpg';
import user2 from 'assets/img/team/2.jpg';
import user3 from 'assets/img/team/3.jpg';
import user4 from 'assets/img/team/4.jpg';
import user5 from 'assets/img/team/5.jpg';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import TransactionsTable from 'components/transactions/TransactionTable';
import { toast } from 'react-toastify';
import Loader from 'components/common/Loader';
import { numberFormatter } from 'helpers/utils';
import { Col, Row } from 'react-bootstrap';
import LastUpdated from 'components/common/LastUpdated';

export const projectUsers = [
  {
    id: 1,
    img: user1
  },
  {
    id: 2,
    img: user2
  },
  {
    id: 3,
    img: user3
  },
  {
    id: 4,
    img: user4
  },
  {
    id: 5,
    img: user5
  },
  {
    id: 6,
    name: '+50'
  }
];

export const generateInventoryBreakdown = (total, available, projected, used, sold) => ([
  {
    id: 1,
    project: 'Available to Allocate',
    amount: numberFormatter((available / total) * 100),
    team: `${numberFormatter(available)} MT`,
    iconColor: 'text-primary'
  },
  {
    id: 2,
    project: 'Projections Margin',
    amount: numberFormatter((projected / total) * 100),
    team: `${numberFormatter(projected)} MT`,
    iconColor: 'text-warning'
  },
  {
    id: 3,
    project: 'Allocated',
    amount: numberFormatter((used / total) * 100),
    team: `${numberFormatter(used)} MT`,
    iconColor: 'text-secondary'
  },
  {
    id: 4,
    project: 'Sold',
    amount: numberFormatter((sold / total) * 100),
    team: `${numberFormatter(sold)} MT`,
    iconColor: 'text-info'
  }
]);

const AdminInventory = () => {
  const vacaygreen = useVacaygreen()
  const [transactions, setTransactions] = useState([]);
  const [inventoryBreakdown, setInventoryBreakdown] = useState([]);
  const [metrics, setMetrics] = useState({})
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [maxResults, setMaxResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [refresh, setRefresh] = useState(false);

  const loadTransactions = async () => {
    try {
      setLoadingTransactions(true);
      const response = await vacaygreen.admin.getTransactions(currentPage, pageSize);
      const data = await response.json();
      setTransactions(data.results);
      setMaxResults(data.maxResults);
    } catch (error) {
      toast.error(`Something went wrong loading transactions: ${error.message}`);
    } finally {
      setLoadingTransactions(false);
    }
  }

  const updateInventoryMetrics = async () => {
    try {
      setLoadingMetrics(true);
      await vacaygreen.admin.updateMetrics();
      setRefresh(true);
    } catch (error) {
      toast.error(`Something went updating your metrics, please try again later`, { theme: 'colored' });
    } finally {
      setLoadingMetrics(false);
    }
  }

  const loadMetrics = async () => {
    try {
      setLoadingMetrics(true);
      const response = await vacaygreen.admin.getMetrics();
      const data = await response.json();
      setMetrics(data);
      const { volumePurchased, monthProjection, co2AllocatedMonth, co2SoldMonth } = data.metrics;
      setInventoryBreakdown(generateInventoryBreakdown(volumePurchased, volumePurchased - co2AllocatedMonth, monthProjection, co2AllocatedMonth, co2SoldMonth))
    } catch (error) {
      toast.error(`Something went wrong loading transactions: ${error.message}`);
    } finally {
      setLoadingMetrics(false);
    }
  }

  const onNewTransactionCreated = () => {
    toast.success("Transaction saved successfully!", { theme: 'colored' });
    setRefresh(true);
  }

  useEffect(() => {
    loadTransactions();
  }, [currentPage, refresh])


  useEffect(() => {
    loadMetrics();
  }, [refresh])

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <LastUpdated lastUpdate={metrics.lastUpdate} onRefresh={() => updateInventoryMetrics()} />
        </Col>
      </Row>
      {loadingMetrics ? <Loader /> : <InventoryMetrics
        metrics={metrics}
        projectsTable={inventoryBreakdown}
        projectUsers={projectUsers}
        onNewTransaction={() => onNewTransactionCreated()}
      />}
      {
        loadingTransactions ? <Loader /> : (
          <TransactionsTable
            transactions={transactions}
            pagination={
              {
                maxResults: maxResults,
                currentPage: currentPage,
                pageSize: pageSize,
                onChangePageSize: (pageSize) => setPageSize(pageSize),
                onChangePage: (page) => setCurrentPage(page),
              }
            }
          />
        )
      }
    </>
  )
}

export default AdminInventory