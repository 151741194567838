import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';

const InvoiceLineItems = ({ lineItems, total }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Property</th>
                <th className="border-0 text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              {lineItems.map(line => (
                <tr key={line.id} className="border-200">
                  <td className="align-middle">
                    <h6 className="mb-0 text-nowrap">{line.description}</h6>
                  </td>
                  <td className="align-middle text-end">
                    $
                    {parseFloat(line.total).toLocaleString(
                      'en-US',
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">Total:</th>
                    <td className="fw-semi-bold">
                      $
                      {parseFloat(total).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

InvoiceLineItems.propTypes = {};

export default InvoiceLineItems;
