export const pmRoutes = [{
  label: 'Main Menu',
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-pie',
      to: '/',
      active: true
    },
    {
      name: 'Properties',
      icon: 'hotel',
      to: '/properties',
      active: true
    },
    {
      name: 'Invoices',
      icon: 'file-invoice',
      to: '/invoices',
      active: true
    }
  ]
}];

export const adminRoutes = [{
  label: 'Admin Console',
  children: [
    {
      name: 'Accounts',
      active: true,
      icon: 'user',
      to: '/'
    },
    {
      name: 'Allocations',
      active: true,
      icon: 'list',
      to: '/admin/allocations'
    },
    {
      name: 'Invoices',
      active: true,
      icon: 'file-invoice',
      to: '/admin/invoices'
    },
    {
      name: 'Inventory',
      active: true,
      icon: 'chart-line',
      to: '/admin/inventory'
    },
    {
      name: 'System Audit',
      active: true,
      icon: 'laptop-code',
      to: '/admin/audit'
    }
  ]
}];

const vacayGreenRoutes = { pmRoutes, adminRoutes }

export default vacayGreenRoutes;
