import { authApiFetch } from '../../Vacaygreen'

const VacaygreenSync = {
    
    startPropertiesSyncForAccount : async (accountId) => {
        return authApiFetch(`accounts/${accountId}/sync/properties`, {
            method: "GET"
        });
    },

    getTicket : async (accountId, ticketId) => {
        return authApiFetch(`accounts/${accountId}/sync/tickets/${ticketId}`, {
            method: "GET"
        });
    },

    getLatestPropertySyncTicketInProgress : async (accountId) => {
        return authApiFetch(`accounts/${accountId}/sync/tickets?result=PENDING&label=Property sync`, {
            method: "GET"
        });
    },

}

export default VacaygreenSync;