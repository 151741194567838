import React, { useState, useEffect } from "react";
import moment from "moment";
import { logout } from "./utils";

const AuthContext = React.createContext();

const emptyUser = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  profilePictureUrl: null
}

const AuthProvider = (props) => {
  const [state, setState] = useState({
    token: localStorage.getItem("token"),
    currentUser: localStorage.getItem("current_user") != "" ? JSON.parse(localStorage.getItem("current_user")) : null,
    account: localStorage.getItem("account_id"),
    user: localStorage.getItem("user") != null ? JSON.parse(localStorage.getItem("user")) : emptyUser,
  });

  //save user info in local storage
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.user));
    console.log("main user persisted")
  }, [state.user]);

  const error = new Error('Something went wrong');

  const login = async (data) => {
    var user = { ...data.user }

    if (data.user.addresses && data.user.addresses.length > 0) {
      user = { ...user, address: { ...data.user.addresses[0] } }
    }

    setState({
      token: data.token,
      user: user,
      account: data.user.mainAccount.id
    });

    localStorage.setItem("token", data.token); //for auth
    localStorage.setItem("account_id", data.user.mainAccount.id);
    //for requests
    console.log("User logged in");
  };

  const setUserProfile = (profile) => {
    setState({
      ...state,
      user: {
        ...state.user,
        ...profile
      }
    });
  }

  const setUserMainAccountState = (accountState) => {
    setState({
      ...state,
      user: {
        ...state.user,
        mainAccount:accountState
      }
    });
  }

  const setAccount = (account) => {
    const currentUser = state.user.accounts.find(item => item.id === account.id)

    setState({
      ...state,
      account: account.id,
      currentUser: currentUser ? currentUser : null,
    });


    localStorage.setItem("account_id", account.id);
    localStorage.setItem("current_user", currentUser ? JSON.stringify(currentUser) : "");
  }

  const register = (data) => {
    setState({ ...state, ...data });
  };

  return (
    <AuthContext.Provider
      value={{ ...state, login, logout, setUserProfile, setAccount, setUserMainAccountState }} {...props}>
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };