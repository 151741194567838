import React, { useEffect, useState } from 'react';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import { useParams } from 'react-router-dom';
import InvoiceDetails from 'components/invoices/invoices-details/InvoiceDetails';
import { toast } from 'react-toastify';
import Loader from 'components/common/Loader';

const AdminInvoiceDetails = () => {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState();
  const [loading, setLoading] = useState(false);

  const vacaygreen = useVacaygreen();

  const loadInvoice = async () => {
    try {
      const response = await vacaygreen.admin.getInvoice(invoiceId);
      const data = await response.json();
      setInvoice(data);
    } catch (error) {
      toast.error(`Something went wrong loading invoice's details: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadInvoice();
  }, [])

  return (loading ? <Loader /> :
    <InvoiceDetails invoice={invoice} />
  );
};

export default AdminInvoiceDetails;
