import { authApiFetch } from '../../Vacaygreen'

const VacaygreenProperties = {
    getProperty: async (id) => {
        return authApiFetch(`properties/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getPropertyTransactions: async (id, page, pageSize) => {
        return authApiFetch(`properties/${id}/transactions?page=${page || 0}&pageSize=${pageSize || 50}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
}

export default VacaygreenProperties;