import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import { AuthContext } from 'context/auth/AuthContext';
import Loader from 'components/common/Loader';

const LoginForm = ({ hasLabel, layout }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext)
  const navigate = useNavigate();

  const vacaygreen = useVacaygreen()
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = async e => {

    e.preventDefault();
    setError(null);
    try
    {
      setLoading(true);
      const response = await vacaygreen.auth.login(formData.email, formData.password);
      const authData = await response.json();
      await login(authData);

      const {user} = authData;

      if(user.accounts && user.accounts.length > 0)
        navigate("/authentication/select")
      else{
        const { mainAccount : { onboardingState } } = user
        if(user.role !== 'ADMIN' && onboardingState !== "DONE")
          navigate("/onboarding")
        else
          navigate("/")
      }

    }catch(e){
      console.log(e);
      setError("Couldn't log you in, please check your credentials.");
    }finally{
      setLoading(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="warning">{error}</Alert>}
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
      {loading ? 
        <Loader /> : 
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      }
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
