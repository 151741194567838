import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar } from 'react-bootstrap';

const ProjectTable = ({ data }) => {
  const filtered = data.filter(v => v.amount > 0);
  return (
    <>
      <ProgressBar
        className="overflow-visible my-4 rounded-3"
        style={{ height: '6px' }}
      >
        {filtered.map((item, index) => (
          <ProgressBar
            variant={item.iconColor}
            now={item.amount}
            key={item.id}
            className={classNames('overflow-visible position-relative', {
              'rounded-end rounded-pill': index === 0,
              'rounded-start rounded-pill': index === data.length - 1,
              ' border-end border-white border-2': index !== data.length - 1
            })}
            label={
              <span className="mt-n4 text-900 fw-bold"> {item.amount}%</span>
            }
          />
        ))}
      </ProgressBar>
      {data.map((project, index) => {
        return (
          <Flex
            key={project.id}
            alignItems="center"
            justifyContent="between"
            className={classNames('rounded-3 bg-light p-3 ', {
              'mb-2': index !== data.length - 1
            })}
          >
            <>
              <Link to="#!">
                <h6 className="mb-0">
                  <FontAwesomeIcon
                    icon="circle"
                    className={`fs--1 me-3 ${project.iconColor}`}
                  />
                  {project.project}
                </h6>
              </Link>
              <Link className="fs--2 text-600 mb-0" to="#!">
                {project.team}
              </Link>
            </>
          </Flex>
        );
      })}
    </>
  );
};

ProjectTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      project: PropTypes.string.isRequired,
      team: PropTypes.string.isRequired,
      iconColor: PropTypes.isRequired
    })
  )
};

export default ProjectTable;
