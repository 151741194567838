import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';


const StyledToastContainer = styled(ToastContainer)`
  &&& {
    z-index: 9999; 
    position: fixed !important;
  }
`

const ErrorToast = ({ message, onClose }) => {
  const [show, setShow] = useState(true);
  return (
    <StyledToastContainer className="p-6" position="bottom-end">
      <Toast show={show} onClose={() => { setShow(!show); onClose && onClose(); }}>
        <Toast.Header>
          <strong className="me-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </StyledToastContainer>
  )
};

export default ErrorToast;
