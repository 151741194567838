/* eslint-disable react-hooks/exhaustive-deps */
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import ErrorToast from 'components/errors/ErrorToast';
import { toCurrency } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const PropertyPerformance = ({ property, selectedYear }) => {
  const date = new Date();
  const isFutureYear = date.getFullYear() < parseInt(selectedYear);
  const target = useRef(null);
  const renderPopover = (monthPerf, profit, revenue) => {
    const { usedNightFee, billedAmount, estimatedProfit, actualProfit, projectedRevenue, actualRevenue } = monthPerf || {};
    return (
      !monthPerf ?
        <Popover id="popover-basic">
          <Popover.Body>
            <p className={`fs-0`}>Projected Revenue: <span className={`fs-0 ${revenue > 0 ? 'text-success' : 'text-danger'}`}>{toCurrency(revenue)}</span></p>
            <p className={`fs-0`}>Projected Profit: <span className={`fs-0 ${profit > 0 ? 'text-success' : 'text-danger'}`}>{toCurrency(profit)}</span></p>
          </Popover.Body>
        </Popover> :
        <Popover id="popover-basic">
          <Popover.Body>
            <p className={`fs-0`}>Night Fee: <span className={`fs-0`}>{toCurrency(usedNightFee)}</span></p>
            <p className={`fs-0`}>Carbon Offset Cost: <span className={`fs-0`}>{toCurrency(billedAmount)}</span></p>
            <p className={`fs-0`}>Projected Revenue: <span className={`fs-0 ${projectedRevenue > 0 ? 'text-success' : 'text-danger'}`}>{toCurrency(projectedRevenue)}</span></p>
            <p className={`fs-0`}>Actual Revenue: <span className={`fs-0 ${actualRevenue > 0 ? 'text-success' : 'text-danger'}`}>{toCurrency(actualRevenue)}</span></p>
            <p className={`fs-0`}>Projected Net Income: <span className={`fs-0 ${estimatedProfit > 0 ? 'text-success' : 'text-danger'}`}>{toCurrency(estimatedProfit)}</span></p>
            <p className={`fs-0`}>Net Income: <span className={`fs-0 ${actualProfit > 0 ? 'text-success' : 'text-danger'}`}>{toCurrency(actualProfit)}</span></p>
          </Popover.Body>
        </Popover>
    );
  }
  return (
    <Row className='py-1'>
      <Col xs="2">
        <span className={`fs-0 text-primary`}>{property.code}</span>
      </Col>
      <Col xs="10">
        <Row>
          {property.performance[selectedYear].map((monthPerf, indexOf) => {
            const { actualProfit } = monthPerf || {};
            return (
              <Col xs="1" ref={target} key={`month-${indexOf}`}>
                {
                  <OverlayTrigger trigger="click" placement="right" overlay={renderPopover(monthPerf, property.monthsProjectedProfit ? property.monthsProjectedProfit[indexOf] : 0, property.monthsProjectedRevenue ? property.monthsProjectedRevenue[indexOf] : 0)}>
                    <Button className='p-1 bg-transparent text-secondary'>
                      {!monthPerf ? (isFutureYear || indexOf >= date.getMonth()) && property.monthsProjectedProfit ? <span className={`fs-0  text-warning`}>{toCurrency(property.monthsProjectedProfit[indexOf])}</span> :
                        <span className={`fs-0  text-disabled`}>N/A</span>
                        :
                        <span className={`fs-0 ${actualProfit > 0 ? 'text-success' : 'text-danger'}`}>{toCurrency(actualProfit)}</span>
                      }
                    </Button>
                  </OverlayTrigger>
                }
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

const PortfolioPerformance = () => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState()
  const [performances, setPerformances] = useState([]);
  const [offsetStatus, setOffsetStatus] = useState(null);
  const [selectedYear, setSelectedYear] = useState("2022");
  const vacaygreen = useVacaygreen();

  const generateMonthPerformance = (performances) => {
    const months = {
      "2022": [null, null, null, null, null, null, null, null, null, null, null, null],
      "2023": [null, null, null, null, null, null, null, null, null, null, null, null],
      "2024": [null, null, null, null, null, null, null, null, null, null, null, null],
    };
    for (let i = 0; i < performances.length; i++) {
      const p = performances[i];
      if (!p) break;
      const split = p.date.split('-');
      const year = parseInt(split[0]);
      const index = parseInt(split[1]);
      months[year][index - 1] = p;
    }
    return months;
  }

  const getPropertiesPerformance = async () => {
    try {
      setLoading(true);
      const propertiesResponse = await vacaygreen.pms.getProperties(0, 500, offsetStatus);
      const response = await vacaygreen.pms.getPropertiesPerformance(0, 500, offsetStatus);
      if (response.ok) {
        const propsResponse = await propertiesResponse.json();
        const perfResponse = await response.json();

        const propertiesPerformances = Object.keys(perfResponse).map(key => {
          const property = propsResponse.results.find(p => p.code === key)
          return { ...property, performance: generateMonthPerformance(perfResponse[key]) };
        })

        setPerformances(propertiesPerformances);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong loading your portfolio, try again later`, { theme: 'colored' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPropertiesPerformance();
  }, [offsetStatus])

  return (
    <>
      {errorMsg && <ErrorToast message={errorMsg} onClose={() => setErrorMsg()} />}
      {loading ? <Loader /> :
        <>
          <FalconCardHeader
            title="Portfolio Performance per Month"
            light
            titleTag="h6"
            endEl={
              <Flex>
                <Form.Select value={selectedYear} size="sm" style={{ width: 'auto' }} className="me-2" onChange={(e) => {
                  setSelectedYear(e.target.value || null)
                }
                }>
                  <option value="2023" selected={selectedYear === "2023"}>2023</option>
                  <option value="2024" selected={selectedYear === "2024"}>2024</option>
                </Form.Select>

                <Form.Select value={offsetStatus} size="sm" style={{ width: 'auto' }} className="me-2" onChange={(e) => {
                  setOffsetStatus(e.target.value || null)
                }
                }>
                  <option value="" selected={!offsetStatus}>Entire Porfolio</option>
                  <option value="ENABLED" selected={offsetStatus === "ENABLED"}>Carbon neutral</option>
                  <option value="DISABLED" selected={offsetStatus === "DISABLED"}>Non carbon neutral</option>
                </Form.Select>
              </Flex>
            }
          />

          <Card.Body className="py-0">
            <>
              <Row>
                <Col xs="2"></Col>
                <Col xs="10">
                  <Row>
                    <Col xs="1">Jan</Col>
                    <Col xs="1">Feb</Col>
                    <Col xs="1">Mar</Col>
                    <Col xs="1">Apr</Col>
                    <Col xs="1">May</Col>
                    <Col xs="1">Jun</Col>
                    <Col xs="1">Jul</Col>
                    <Col xs="1">Aug</Col>
                    <Col xs="1">Sep</Col>
                    <Col xs="1">Oct</Col>
                    <Col xs="1">Nov</Col>
                    <Col xs="1">Dec</Col>
                  </Row>
                </Col>

              </Row>
              {(!performances || performances.length === 0) &&
                <span className="text-800">
                  No properties in your portfolio.
                </span>
              }

              {performances && performances.length > 0 && performances.map((property, index) => (
                <PropertyPerformance
                  property={property}
                  selectedYear={selectedYear}
                  key={index}
                />
              ))}
            </>
          </Card.Body>
        </>
      }
    </>
  )
}

export default PortfolioPerformance