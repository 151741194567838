import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import BasicECharts from 'components/common/BasicEChart';
import { getColor } from 'helpers/utils';

echarts.use([LineChart, CanvasRenderer]);

const getOptions = data => ({
  tooltip: {
    show: false
  },
  series: [
    {
      type: 'bar',
      data,
      symbol: 'none',
      itemStyle: {
        color: getColor('primary'),
        borderRadius: [5, 5, 0, 0]
      }
    }
  ],
  grid: { right: '10px', left: '0', bottom: '0', top: '0' }
});

const PmsSyncStats = ({ data, title }) => {
  return (
    <Card>
      <Card.Body className="py-5 py-sm-3">
        <Row className="g-5 g-sm-0">
          <Col>
            <div>
              <h6 className="mb-md-0 mb-lg-0 mb-xl-3">{title}</h6>
              <h3 className="fw-normal text-center text-success">
                {data.value}
              </h3>
              <BasicECharts
                echarts={echarts}
                options={getOptions(data.chartData)}
                style={{ height: '1.875rem' }}
              />
              <p className="fs--2 mb-0 text-500">
                {data.success || 0}/{data.total} Successful Syncs in 30 days
              </p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

PmsSyncStats.propTypes = {
  data: PropTypes.object // TODO: define shape
};

export default PmsSyncStats;
