import { apiFetch} from '../../Vacaygreen'

const VacaygreenAuth = {
    
    /**
     * User sign in 
     * 
     * @param {*} email 
     * @param {*} password 
     * @returns 
     */
    login : async (email, password) => {
        return apiFetch("sessions/signin", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email:email,
                password:password
            }),
        });
    },

    /**
     * User sign up 
     * 
     * @param {*} email 
     * @param {*} password 
     * @returns 
     */
     signup : async (companyName, firstName, lastName, email, password, passwordConfirm) => {
        return apiFetch("accounts/signup", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email:email,
                password:password,
                passwordConfirm: passwordConfirm,
                firstName:firstName,
                lastName: lastName,
                companyName: companyName
            }),
        });
    },


    /**
     * User password forgot request 
     * 
     * @param {*} email 
     * @param {*} password 
     * @returns 
     */
     forgot : async (email) => {
        return apiFetch("sessions/recover", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email:email
            }),
        });
    }
}

export default VacaygreenAuth;