/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import ConfirmModal from 'components/common/ConfirmModal';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import Loader from 'components/common/Loader';
import ErrorToast from 'components/errors/ErrorToast';
import { numberFormatter } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import { mapPropertyType } from 'utils/TextMappers';
import AddPropertyForm from './AddPropertyForm';
import PortfolioImportForm from './PropertyBulkUploadForm';

const Property = ({ property, isLast, handleOffsetStatusChanged }) => {
  const { id, code, propertyType, address, billingCycleCostEstimation, billingCycleUsageEstimation, projectedNightRate, sqft, sleeps, bedrooms, bathrooms, offsetStatus, emissionsPerDay, name, } = property;
  const [propertyOffsetStatus, setPropertyOffsetStatus] = useState(offsetStatus);
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <Row
      className={classNames('align-items-center py-2', {
        'border-bottom border-200 ': !isLast
      })}
    >
      <Col xs="8" className="py-1">
        <Flex className="align-items-center">
          <div className="avatar avatar-xl me-3">
            <ConfirmModal isOpen={showConfirmation} setIsOpen={() => setShowConfirmation(false)}
              confirm={async (e) => {
                try {
                  handleOffsetStatusChanged && await handleOffsetStatusChanged(id, propertyOffsetStatus === "DISABLED" ? "ENABLED" : "DISABLED", code)
                  setPropertyOffsetStatus(propertyOffsetStatus === "DISABLED" ? "ENABLED" : "DISABLED");
                } catch (error) {

                } finally {
                  setShowConfirmation(false);
                }
              }}
              header={`Are you sure you want to ${propertyOffsetStatus === "DISABLED" ? "enable" : "disable"} this property?`}
              body={(
                <div>
                  <p>Please confirm that you really want to {propertyOffsetStatus === "DISABLED" ? "enable" : "disable"} this property</p>
                  {propertyOffsetStatus === "DISABLED" && (<p>If you enable this property, it cannot be disabled until next billing cycle.</p>)}
                </div>
              )}
            />
            <Form.Check
              type="switch"
              id="allDay"
              label=""
              name="allDay"
              checked={propertyOffsetStatus === "ENABLED"}
              onChange={() => setShowConfirmation(true)}
            />
          </div>
          <Flex className="position-relative">
            <Flex align="start" className="mb-0" direction={"column"}>
              <span className={`fs-0 fw-semi-bold text-primary`}>{name} ({code})</span>
              <span className="text-800 stretched-link">
                {address}
              </span>
              <Flex tag="h6" justifyContent="start" className="mb-0">
                <span className={`fs--1 text-primary`}>{mapPropertyType(propertyType)}</span>
                <span className={`fs--1 text-primary mx-2`}>|</span>
                <span className={`fs--1 text-primary`}>{sqft} sqft</span>
                <span className={`fs--1 text-primary mx-2`}>|</span>
                <span className={`fs--1 text-primary`}>{sleeps} occupants</span>
                <span className={`fs--1 text-primary mx-2`}>|</span>
                <span className={`fs--1 text-primary`}>{bedrooms} bedrooms</span>
                <span className={`fs--1 text-primary mx-2`}>|</span>
                <span className={`fs--1 text-primary`}>{bathrooms} bathrooms</span>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col className="pe-card">
            {!!projectedNightRate &&
              <span className="fs--1 text-primary">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(projectedNightRate)} / night</span>
            }
          </Col>
          <Col className="pe-card">
            <span className="fs--1 fw-semi-bold">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(billingCycleCostEstimation)} / month</span>
          </Col>
          <Col className="pe-card">
            <span className={`fs--1 text-primary`}>{numberFormatter(billingCycleUsageEstimation)}t / month</span>
          </Col>
        </Row>
      </Col >
    </Row >
  );
};

const PortfolioList = ({ data }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMsg, setErrorMsg] = useState()
  const [properties, setProperties] = useState([]);
  const [offsetStatus, setOffsetStatus] = useState(null);
  const [maxResults, setMaxResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [refresh, setRefresh] = useState(false);
  const vacaygreen = useVacaygreen();

  const getProperties = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.pms.getProperties(currentPage, pageSize, offsetStatus);
      if (response.ok) {
        const paginationResponse = await response.json();
        setProperties(paginationResponse.results);
        setMaxResults(paginationResponse.maxResults)
      }
    } catch (error) {
      toast.error(`Something went wrong loading your portfolio, try again later`, { theme: 'colored' });
    } finally {
      setLoading(false);
    }
  }

  const saveProperty = async (property) => {
    try {
      setLoading(true);
      const response = await vacaygreen.pms.createProperty(property);
      if (response.ok) {
        setShowCreateModal(false);
        setRefresh(true);
        toast.success(`Property ${property.name} successfully created!`, { theme: 'colored' });
      }
    } catch (error) {
      toast.error(`Something went wrong loading your portfolio, try again later`, { theme: 'colored' });
    } finally {
      setLoading(false);
    }
  }

  const uploadPortfolio = async (portfolioFile) => {
    try {
      setLoading(true);
      const response = await vacaygreen.pms.uploadPortfolio(portfolioFile);
      if (response.ok) {
        const result = await response.json();
        setShowCreateModal(false);
        setRefresh(true);
        toast.success(`${result.length} new properties were successfully created!`, { theme: 'colored' });
      }
    } catch (error) {
      toast.error(`Something went wrong loading your portfolio, try again later`, { theme: 'colored' });
    } finally {
      setLoading(false);
    }
  }

  const enableEntirePortfolio = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.pms.enableEntirePortfolio();
      if (response.ok) {
        const result = await response.json();
        setShowConfirmation(false);
        setRefresh(true);
        toast.success(`${result} properties where successfully enabled!`, { theme: 'colored' });
      }
    } catch (error) {
      toast.error(`Something went wrong updating your portfolio, try again later`, { theme: 'colored' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProperties();
  }, [offsetStatus, currentPage, refresh])

  return (
    <>
      <ConfirmModal
        isOpen={showConfirmation}
        setIsOpen={() => setShowConfirmation(false)}
        confirm={() => enableEntirePortfolio()}
        header="Enable all remaining properties?"
        body={<p>Are you sure you want to enable all non-suspended properties? (suspended properties won't be enabled)</p>}
      />
      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Properties to Portfolio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="once">
            <Tab eventKey="once" title="New" className='border-bottom border-x p-3'>
              <AddPropertyForm
                onSave={
                  (property) => {
                    saveProperty(property);
                  }
                }
              />
            </Tab>
            <Tab eventKey="bulk" title="Import" className='border-bottom border-x p-3'>
              <PortfolioImportForm onSave={
                (portfolioFile) => {
                  uploadPortfolio(portfolioFile);
                }
              } />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      {errorMsg && <ErrorToast message={errorMsg} onClose={() => setErrorMsg()} />}
      {loading ? <Loader /> :
        <>
          <FalconCardHeader
            title="Portfolio"
            light
            titleTag="h6"
            endEl={
              <Flex>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="list"
                  transform="shrink-3"
                  className="mx-2"
                  onClick={() => setShowConfirmation(true)}
                >
                  <span className="d-none d-sm-inline-block ms-1">Enable All</span>
                </IconButton>

                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => setShowCreateModal(true)}
                >
                  <span className="d-none d-sm-inline-block ms-1">Add</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="arrow-down"
                  transform="shrink-3"
                  className="mx-2"
                  href={`${process.env.PUBLIC_URL}/Portfolio_template.xlsx`}
                  download
                >
                  Download Template
                </IconButton>
                <Form.Select value={offsetStatus} size="sm" style={{ width: 'auto' }} className="me-2" onChange={(e) => {
                  setCurrentPage(0);
                  setOffsetStatus(e.target.value || null)
                }
                }>
                  <option value="" selected={!offsetStatus}>Entire Porfolio</option>
                  <option value="ENABLED" selected={offsetStatus === "ENABLED"}>Carbon neutral</option>
                  <option value="DISABLED" selected={offsetStatus === "DISABLED"}>Non carbon neutral</option>
                </Form.Select>
              </Flex>
            }
          />

          <Card.Body className="py-0">
            {(!properties || properties.length === 0) &&
              <span className="text-800">
                No properties in your portfolio.
              </span>
            }

            {properties && properties.map((property, index) => (
              <Property
                property={property}
                isLast={index === properties.length - 1}
                key={property.id}
                handleOffsetStatusChanged={
                  async (id, status, code) => {
                    const response = await vacaygreen.pms.setOffsetStatus(id, status)
                    if (!response.ok) {
                      if (response.status === 400) {
                        toast.warning(`Property ${code} must have passed at least one billing cycle to be inactivated`)
                        throw new Error(`Property ${code} must have passed at least one billing cycle to be inactivated`);
                      }
                      if (response.status === 500) {
                        toast.error(`Property ${code} couldn't be updated. Contact your administrator.`, { theme: 'colored' })
                        throw new Error();
                      }
                    }
                  }
                }
              />
            ))}
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              canPreviousPage={currentPage !== 0}
              canNextPage={currentPage !== Math.floor(maxResults / pageSize)}
              previousPage={() => setCurrentPage(currentPage - 1)}
              nextPage={() => setCurrentPage(currentPage + 1)}
              pageCount={Math.ceil(maxResults / pageSize)}
              pageIndex={currentPage}
              gotoPage={(page) => setCurrentPage(page)}
            />
          </Card.Footer>
        </>
      }
    </>
  )
}

export default PortfolioList