import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const InvoiceInfo = ({ addressInfo }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Billing Address</h5>
            <h6 className="mb-2">{addressInfo.name}</h6>
            <p className="mb-1 fs--1">
              {addressInfo.address}
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Phone: {addressInfo.phone}
              </strong>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

InvoiceInfo.propTypes = {};

export default InvoiceInfo;
