import Loader from 'components/common/Loader';
import PropertyDetails from 'components/portfolio/PropertyDetails';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const AdminPropertyDetails = () => {
  const { propertyId } = useParams();
  const [allocationList, setAllocationList] = useState([]);
  const [property, setProperty] = useState();
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [loadingProperty, setLoadingProperty] = useState(false);
  const [maxResults, setMaxResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const vacaygreen = useVacaygreen();

  const loadTransactions = async () => {
    try {
      setLoadingTransactions(true);
      const response = await vacaygreen.properties.getPropertyTransactions(propertyId, currentPage, pageSize);
      const data = await response.json();
      setAllocationList(data.results);
      setMaxResults(data.maxResults)
    } catch (error) {
      toast.error(`Something went wrong loading property's transactions: ${error.message}`);
    } finally {
      setLoadingTransactions(false);
    }
  }

  const loadProperty = async () => {
    try {
      setLoadingProperty(true);
      const response = await vacaygreen.properties.getProperty(propertyId);
      const data = await response.json();
      setProperty(data);
    } catch (error) {
      toast.error(`Something went wrong loading properties: ${error.message}`);
    } finally {
      setLoadingProperty(false);
    }
  }

  useEffect(() => {
    loadProperty();
  }, [])

  useEffect(() => {
    loadTransactions();
  }, [currentPage])

  return (loadingProperty || loadingTransactions ? <Loader /> :
    <PropertyDetails
      property={property}
      transactions={allocationList}
      pagination={
        {
          maxResults: maxResults,
          currentPage: currentPage,
          pageSize: pageSize,
          onChangePageSize: (pageSize) => setPageSize(pageSize),
          onChangePage: (page) => setCurrentPage(page),
        }
      }
    />
  )
}

export default AdminPropertyDetails;