import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/auth/AuthContext';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import { useParams } from 'react-router-dom';
import InvoiceDetails from 'components/invoices/invoices-details/InvoiceDetails';

const PMInvoiceDetails = () => {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState();
  const { user } = useContext(AuthContext);

  const vacaygreen = useVacaygreen();

  const loadInvoice = async () => {
    const response = await vacaygreen.account.getInvoice(user.mainAccount.id, invoiceId);
    const data = await response.json();
    setInvoice(data);
  }

  useEffect(() => {
    loadInvoice();
  }, [])

  return (
     <InvoiceDetails invoice={invoice} />
  );
};

export default PMInvoiceDetails;
