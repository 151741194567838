import { authApiFetch } from '../../Vacaygreen'

const VacaygreenOnboarding = {

	setOnboardingState: async (accountId, state) => {
		return authApiFetch(`accounts/${accountId}/onboarding-state/${state}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			}
		});
	},

	setAccountInfo: async (accountId, accountInfo) => {
		return authApiFetch(`accounts/${accountId}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(accountInfo)
		});
	},

	setPMS: async (accountId, pmsInfo) => {
		return authApiFetch(`accounts/${accountId}/pms`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(pmsInfo)
		});
	},

	getPMSProviders: async () => {
		return authApiFetch(`pms-connectors`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			}
		});
	}
}

export default VacaygreenOnboarding;