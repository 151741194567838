import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "context/auth/AuthContext";
import { useVacaygreen } from "services/vacaygreen/Vacaygreen";

const OnboardingContext = React.createContext();

const OnboardingProvider = ({children}) => {
  const vacaygreen = useVacaygreen();
  const [ step, setStep ] = useState(1);
  const [ currentTicket, setCurrentTicket ] = useState();
  const [ nextDisabled, setNextDisabled ] = useState(false);
  const [ backDisabled, setBackDisabled ] = useState(false);
  const [ accountInfo, setAccountInfo ] = useState({})

  const { user, setUserMainAccountState, account } = useContext(AuthContext); 

  const { mainAccount } = user;

  const setOnboardingStep = (step) => {
    setUserMainAccountState({...user.mainAccount, onboardingState:step})
  }

  const setCurrentSyncTicket = (ticket) => {
    setCurrentTicket(ticket);
    localStorage.setItem("ticket", ticket);
  }

  const clearCurrentSyncTicket = () => {
    setCurrentTicket(null);
    localStorage.removeItem("ticket")
  }

  const getCurrentSyncTicket = async () => {
    const savedTicket = localStorage.getItem("ticket");
    if(!savedTicket){
      const result = await vacaygreen.sync.getLatestPropertySyncTicketInProgress(account);
      if(result.ok){
        const tickets = await result.json();
        return tickets && tickets.length > 0 ? tickets[0].id : null;
      }
    } 
    return savedTicket;
  }

  useEffect(() => {
    setAccountInfo({
      ...accountInfo, 
      address1: mainAccount.address?.street, 
      city: mainAccount.address?.city,
      state: mainAccount.address?.state,
      zipCode: mainAccount.address?.zipCode,
      companyName: mainAccount.name,
    })

    const initTicket = async () =>{
      setCurrentTicket(await getCurrentSyncTicket())
    }
    initTicket()
  }, [ mainAccount ])

  return (
    <OnboardingContext.Provider
      value={{ mainAccount, step, setStep, accountInfo, setAccountInfo, nextDisabled, setNextDisabled, setOnboardingStep, getCurrentSyncTicket, currentTicket, setCurrentSyncTicket, backDisabled, setBackDisabled, clearCurrentSyncTicket }}>
        {children}
    </OnboardingContext.Provider>
  );
}

export { OnboardingProvider, OnboardingContext };