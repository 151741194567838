import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import CustomerDetailsHeader from './CustomerDetailsHeader';
import CustomerInfo from './CustomerInfo';
import CustomerLog from './CustomerLog';

const CustomersDetails = () => {
  
  let { id } = useParams();
  const vacaygreen = useVacaygreen();
  const [selectedAccount, setSelectedAccount] = useState(null)

  const loadAccountDetails = async (id) => {
    try
    {
      const response = await vacaygreen.admin.getAccount(id)
      const data = await response.json()
      setSelectedAccount(data);
    }finally{

    }
  }

  useEffect(() => {
    loadAccountDetails(id)
  }, [])

  return (
    <>
      {!selectedAccount ? 'Loading...' : 
      <>
        <CustomerDetailsHeader account={selectedAccount}/>
        <CustomerInfo account={selectedAccount}/>
      </>}
    </>
  );
};

export default CustomersDetails;
