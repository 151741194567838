/* eslint-disable react-hooks/exhaustive-deps */
import PMSDropdown from 'components/pms/PMSDropdown';
import { OnboardingContext } from 'context/onboarding/OnboardingContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

const DatasourceForm = ({ register, errors, watch, pmss, syncError }) => {
  const { setNextDisabled, step } = useContext(OnboardingContext);
  const pms = watch('pms');
  useEffect(() => {
    if (step === 2)
      setNextDisabled(true)
  }, [setNextDisabled, step])

  useEffect(() => {
    if (pms && pms !== '') {
      setNextDisabled(false)
    }
  }, [pms])

  return (
    <>
      {syncError && <div className="alert alert-danger" role="alert">The property synchronization failed, please make sure the configuration you provided is ok.<br /><br /> Please <a href="mailto:support@vacaygreen.com">contact support</a> otherwise.</div>}
      <h3>
        Excellent! Now let's get your data flowing 🚀
      </h3>
      <PMSDropdown
        register={register}
        errors={errors}
        watch={watch}
        pmss={pmss}
      />
    </>
  );
};

DatasourceForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default DatasourceForm;
