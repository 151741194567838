import Loader from 'components/common/Loader';
import InvoicesList from 'components/invoices/invoices-list/InvoicesList';
import { AuthContext } from 'context/auth/AuthContext';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';


const PMInvoices = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const vacaygreen = useVacaygreen();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [maxResults, setMaxResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const loadInvoices = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.account.getInvoices(user.mainAccount.id, currentPage, pageSize);
      const data = await response.json();
      setInvoiceList(data.results);
      setMaxResults(data.maxResults);
    } catch (error) {
      toast.error(`Something went wrong loading invoices, please try again later.`);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadInvoices();
  }, [currentPage])

  return (loading ? <Loader /> :
    <InvoicesList
      invoices={invoiceList}
      pagination={
        {
          maxResults: maxResults,
          currentPage: currentPage,
          pageSize: pageSize,
          onChangePageSize: (pageSize) => setPageSize(pageSize),
          onChangePage: (page) => setCurrentPage(page),
        }
      }
    />
  )
}

export default PMInvoices;