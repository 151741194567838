import FalconCardHeader from 'components/common/FalconCardHeader';
import { AuthContext } from 'context/auth/AuthContext';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const ReservationSettings = () => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    estimated_occupancy: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    property_margin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  });
  const vacaygreen = useVacaygreen();

  const getSettings = async e => {
    setLoading(true)
    try {
      const response = await vacaygreen.account.getSettings(user.mainAccount.id)
      if (response.ok) {
        const data = await response.json();
        setFormData({ ...formData, ...data })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  const updateSettings = async e => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await vacaygreen.account.updateSettings(user.mainAccount.id, formData)
      if (response.ok) {
        toast.success(`Settings updated!`, { theme: 'colored' });
      }
    } catch (e) {
      toast.error(`Something went wrong updating your settings, try again later`, { theme: 'colored' });
    } finally {
      setLoading(false)
    }
  };

  const handleCheckboxChange = (e, i) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });
  };

  useEffect(() => {
    getSettings();
  }, [])

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Reservation Settings" />
      <Form onSubmit={updateSettings} noValidate >
        <Card.Body className="bg-light">
          <Form.Check type="checkbox" disabled={loading}>
            <Form.Check.Input type="checkbox" name="send_certs" onChange={handleCheckboxChange} checked={formData.send_certs} />
            <Form.Check.Label className="ms-2">Send certificates to guests when they arrive to the property</Form.Check.Label>
          </Form.Check>
          <Form.Group className="mb-3" controlId="heading">
            <Form.Label><b>Defualt Estimated Occupancy</b></Form.Label>
            <Row>
              {formData.estimated_occupancy.map((v, i) => (
                <Form.Group as={Col} lg={4}>
                  <Form.Label>{MONTHS[i]}</Form.Label>
                  <Form.Control
                    type="number"
                    disabled={loading}
                    placeholder={v}
                    value={formData.estimated_occupancy[i]}
                    name={`estimated_occupancy-${i}`}
                    onChange={(e) => {
                      formData.estimated_occupancy[i] = e.target.value;
                      setFormData({
                        ...formData,
                        "estimated_occupancy": formData.estimated_occupancy
                      });
                    }}
                  />
                </Form.Group>
              ))
              }
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="heading">
            <Form.Label><b>Desired Profit Margin</b></Form.Label>
            <Row>
              {formData.property_margin.map((v, i) => (
                <Form.Group as={Col} lg={4}>
                  <Form.Label>{MONTHS[i]}</Form.Label>
                  <Form.Control
                    type="number"
                    disabled={loading}
                    placeholder={v}
                    value={v}
                    name={`property_margin-${i}`}
                    onChange={(e) => {
                      formData.property_margin[i] = e.target.value;
                      setFormData({
                        ...formData,
                        "property_margin": formData.property_margin
                      });
                    }}
                  />
                </Form.Group>
              ))
              }
            </Row>
          </Form.Group>
          <div className="text-end">
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? '...' : 'Update'}
            </Button>
          </div>
        </Card.Body>
      </Form>
    </Card >
  );
};

export default ReservationSettings;
