/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import WizardInput from '../wizard/WizardInput';

const PMSDropdown = ({ register, errors, watch, pmss }) => {
  const [selectedPMS, setSelectedPMS] = useState(null)
  const pms = watch('pms');
  useEffect(() => {
    if (pms && pms !== '') {
      setSelectedPMS(pmss.find((v) => v.name === pms))
    }
  }, [pms])

  const dropdownPMS = pmss.map((pms) => ({ value: pms.name, label: pms.displayName, authType: pms.authFields }))

  return (
    <>
      <WizardInput
        type="select"
        label="Which of these PMS do you use?"
        name="pms"
        placeholder="Select your PMS"
        errors={errors}
        disabled={pmss.lenght === 0}
        options={[
          ...dropdownPMS,
          {
            label: 'None of these',
            value: 'NONE'
          }]}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('pms', {
            required: "We need to know what PMS do you use"
          })
        }}
        watch={watch}
      />
      {selectedPMS && pms !== "NONE" && pms !== "" &&
        (
          <>
            {
              Object.keys(selectedPMS.authFields).map(key => (
                <WizardInput
                  key={key}
                  label={selectedPMS.authFields[key]}
                  name={key}
                  errors={errors}
                  formGroupProps={{ className: 'mb-0' }}
                  formControlProps={{
                    ...register(key, {
                      required: `This parameter is required to operate with ${selectedPMS.displayName}`
                    }),
                  }}
                />
              ))
            }
            <small>If you don't know your authentication information please contact {selectedPMS.displayName} support to ask for it.</small>
          </>
        )
      }
      {pms === "NONE" &&
        <>
          <p>Please <strong>contact us</strong> and tell us about the PMS you use and we will make our best to support it in the near future. <strong>Don't worry</strong>, in the meantime we will follow up with alternate methods to get you going.</p>
          <Button href="mailto:support@vacaygreen.com">Contact Us</Button>
        </>
      }
    </>
  );
};

export default PMSDropdown;
