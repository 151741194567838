import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { Alert, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import changeLogs from './changelogs';
import Logs from './Logs';
import FalconEditor from 'components/common/FalconEditor';

const Changelog = () => (
  <>
    <PageHeader title="Changelog" className="mb-3" />
    
    {changeLogs.map((logs, index) => (
      <Logs {...logs} index={index} key={index} />
    ))}
  </>
);

export default Changelog;
