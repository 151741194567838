import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';

import PropertiesTable from '../../components/dashboards/vacaygreen-pm/PropertiesTable';
import StatisticsCard from '../../components/dashboards/vacaygreen-pm/StatisticsCard';
import GaugeChartCard from '../../components/dashboards/vacaygreen-pm/GaugeChartCard';
import PmsSyncStats from '../../components/dashboards/vacaygreen-pm/PmsSyncStats';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import { AuthContext } from 'context/auth/AuthContext';
import { toast } from 'react-toastify';
import Loader from 'components/common/Loader';
import LastUpdated from 'components/common/LastUpdated';
import { numberFormatter } from 'helpers/utils';
import StatisticsGridCard from 'components/dashboards/vacaygreen-pm/StatisticsGirdCard';
import ReservationNightRateChart from 'components/dashboards/vacaygreen-pm/ReservationNightRateChart';
import MiniStatisticsCard from 'components/dashboards/vacaygreen-pm/MiniStatisticsCard';
import FinancialChart from 'components/dashboards/vacaygreen-pm/FinancialChart';

const getRNRStats = (rnr, avg) => ({
  title: 'Reservation Nightly Fee',
  value: rnr,
  subTitleValue: avg,
  subTitlePrefix: '$',
  subTitleSuffix: ' annual average',
  decimal: true,
  prefix: '$',
  suffix: '',
  valueClassName: 'text-warning',
  image: bg1
})

const getIncomeStats = (incomeYTD, incompeEOY) => ({
  title: 'Net Income YTD',
  value: incomeYTD,
  subTitleValue: incompeEOY,
  subTitlePrefix: '$',
  subTitleSuffix: ' EOY projected',
  decimal: false,
  prefix: '$',
  suffix: '',
  valueClassName: 'text-warning',
  image: bg2
})

const getCO2OffsetStats = (co2YTD, co2EOY) => ({
  title: 'CO Purchased YTD',
  value: co2YTD,
  subTitleValue: co2EOY,
  subTitlePrefix: '',
  subTitleSuffix: ' MTE EOY projected',
  decimal: true,
  prefix: '',
  suffix: ' MTE',
  valueClassName: 'text-warning',
  image: bg3
})

const getSyncStatusMetrics = (success, errors, items) => {
  return ({
    success,
    total: success + errors,
    value: `${success ? ((success * 100) / (success + errors)).toFixed(2) : 0}%`,
    chartData: items
  });
}

const transformPropertyMetrics = (raw) => {
  return raw.map((p) => ({
    code: p[0],
    name: p[1],
    emissionsByMonth: numberFormatter(p[2] * 30),
    offsetStatus: p[3],
    estimatedOccupancy: p[4]
  }))
}

const transforTreeMetrics = (raw) => {
  return [
    {
      title: 'Your offsets are equivalent to tree seedlings grown for 10 years',
      icon: 'tree',
      amount: raw.savedTrees,
      valueClassName: 'text-success text-center',
      className: 'border-200 border-bottom border-end pb-4'
    },
    {
      title: 'We can do more! Offset all your properties to create the greatest impact!',
      icon: 'tree',
      amount: raw.missedTrees,
      valueClassName: 'text-disabled text-center',
      className: 'border-200 border-bottom border-end pb-4'
    }
  ]
}

const transformKPIMetrics = (data) => {
  return {
    targetMargin: {
      title: 'Target Annual Margin',
      value: data.avgProfitRate,
      decimal: false,
      prefix: '',
      suffix: '%'
    },
    estimatedOccupancyRate: {
      title: 'Estimated Annual Occupancy Rate',
      value: data.avgOccupancyRate,
      decimal: false,
      prefix: '',
      suffix: '%'
    },
    actualOccupancyRate: {
      title: 'Actual Occupancy Rate YTD',
      value: data.ytdOccupancyRate,
      decimal: false,
      prefix: '',
      suffix: '%',
      valueClassName: data.ytdOccupancyRate > data.avgOccupancyRate ? 'text-success' : 'text-warning'
    },
    actualMargin: {
      title: 'Actual Margin YTD',
      value: data.ytdProfitRate,
      decimal: false,
      prefix: '',
      suffix: '%',
      valueClassName: data.ytdProfitRate > data.avgProfitRate ? 'text-success' : 'text-warning'
    }
  }
};

const findByMonth = (list, month) => {
  for (let i = 0; i < list.length; i++) {
    const p = list[i];
    if (!p) break;
    const split = p.date.split('-');
    const index = parseInt(split[1]);
    if (index - 1 === month) return p;
  }
  return {};
}


const financialData = (data, months = 12, isQuarterly) => {
  const base = {
    dates: [],
    carbonOffsetCost: [],
    projectedRevenue: [],
    projectedRevenueFuture: [],
    actualRevenue: [],
    actualRevenueFuture: [],
    netIncome: [],
    netIncomeFuture: [],
    netIncomeCumulative: [],
    netIncomeCumulativeFuture: []
  };

  for (let i = 0; i < months; i++) {
    let bcr = i < data.length ? findByMonth(data, i) : {};
    base.dates.push(`${i + 1}/1/2023`)
    if (isQuarterly) {
      for (let j = 1; j < 3; j++) {
        let bcr2 = i + j < data.length ? findByMonth(data, i + j) : {};
        bcr.carbonOffsetCost += (bcr2.billedAmount || 0)
        bcr.projectedRevenue += (bcr2.projectedRevenue || 0);
        bcr.projectedRevenueFuture += (bcr2.projectedRevenue || 0);
        bcr.actualRevenue += (bcr2.actualRevenue || 0);
        bcr.actualRevenueFuture += (bcr2.actualRevenue || 0);
        bcr.netIncome += (bcr2.actualProfit || 0);
        bcr.netIncomeCumulative += (bcr2.actualProfit || 0);
        bcr.netIncomeCumulativeFuture += (bcr2.actualProfit || 0);
      }
      i = i + 2;
    }
    base.carbonOffsetCost.push(bcr.billedAmount || 0);
    base.projectedRevenue.push(bcr.projectedRevenue || 0);
    base.projectedRevenueFuture.push(bcr.projectedRevenue || 0);
    base.actualRevenue.push(bcr.actualRevenue || 0);
    base.actualRevenueFuture.push(bcr.actualRevenue || 0);
    base.netIncome.push(bcr.actualProfit || 0);
    base.netIncomeCumulative.push(bcr.actualProfit || 0);
    base.netIncomeCumulativeFuture.push(bcr.actualProfit || 0);
  }
  return base;
}

const generateReservationNightRateChartData = (data, settings) => {
  const base = {
    dates: [],
    reservationNightRates: [],
    estimatedOccupancyRates: [],
    targetMargins: [],
    carbonOffsetCost: []
  }
  for (let i = 0; i < 12; i++) {
    let bcr = i < data.length ? findByMonth(data, i) : {};
    base.dates.push(`${i + 1}/1/2023`)
    base.reservationNightRates.push(bcr.usedNightFee || 0);
    base.carbonOffsetCost.push(bcr.billedAmount || 0);
  }
  base.estimatedOccupancyRates = settings.estimated_occupancy;
  base.targetMargins = settings.property_margin;
  return base;
};


const generateFinancialChartData = (data) => ({
  ytdDataByQuarter: financialData(data, new Date().getMonth() + 1, true),
  ytdDataByMonth: financialData(data, new Date().getMonth() + 1),
  annualDataByQuarter: financialData(data, 12, true),
  annualDataByMonth: financialData(data)
});

const getTreeMetricsTitle = (metric) => {
  const { savedTrees, missedTrees } = metric;
  if (!savedTrees || savedTrees < 1) return "You can help the environment with just a click!";
  if (savedTrees > missedTrees) return "Your are making the difference! Thank you!";
  return "Thank you for making the difference! You can still do more!";
}

const PropertyManagerDashboard = () => {
  const vacaygreen = useVacaygreen();
  const { user } = useContext(AuthContext);
  const [accountMetrics, setAccountMetrics] = useState({});
  const [rnrStats, setRnrStats] = useState({});
  const [incomeStats, setIncomeStats] = useState({});
  const [co2Stats, setCO2Stats] = useState({});
  const [portfolioCoverage, setPortfolioCoverage] = useState({});
  const [propertySyncs, setPropertySyncs] = useState({});
  const [reservationSyncs, setReservationSyncs] = useState({});
  const [treeMetrics, setTreeMetrics] = useState({});
  const [kpiMetrics, setKpiMetrics] = useState(null);
  const [topPerformersMetrics, setTopPerformersMetrics] = useState([]);
  const [financialMetrics, setFinancialMetrics] = useState(null);
  const [rnfMetrics, setRnfMetrics] = useState(null);
  const [settings, setSettings] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPortfolioMetrics, setLoadingPortfolioMetrics] = useState(false);

  const getSettings = async e => {
    setLoading(true)
    try {
      const response = await vacaygreen.account.getSettings(user.mainAccount.id)
      if (response.ok) {
        const data = await response.json();
        setSettings(data);
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  const loadAccountMetrics = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.account.getMetrics(user.mainAccount.id);
      const data = await response.json();
      setAccountMetrics(data);
      setRnrStats(getRNRStats(data.rnr, data.rnrAvg));
      setIncomeStats(getIncomeStats(data.netIncomeYTD, data.netIncomeEOY));
      setCO2Stats(getCO2OffsetStats(data.co2PurchasedYTD, data.co2PurchasedEOY));
      if (data.ticketMetrics) {
        setPropertySyncs(getSyncStatusMetrics(data.ticketMetrics.successPropertySyncs, data.ticketMetrics.errorPropertySyncs, data.ticketMetrics.last10PropertySyncs))
        setReservationSyncs(getSyncStatusMetrics(data.ticketMetrics.successReservationSyncs, data.ticketMetrics.errorReservationSyncs, data.ticketMetrics.last10ReservationSyncs))
      }
      data.propertyPerformanceMetricList && setTopPerformersMetrics(transformPropertyMetrics(data.propertyPerformanceMetricList));
      data.treesMetrics && setTreeMetrics(data.treesMetrics);
      setKpiMetrics(transformKPIMetrics(data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const loadFinancialMetrics = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.account.getFinancialMetrics(user.mainAccount.id);
      const data = await response.json();
      setFinancialMetrics(generateFinancialChartData(data));
      setRnfMetrics(generateReservationNightRateChartData(data, settings));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const updateAccountMetrics = async () => {
    try {
      setLoading(true);
      await vacaygreen.account.updateMetrics(user.mainAccount.id);
      setRefresh(true);
    } catch (error) {
      toast.error(`Something went updating your metrics, please try again later`, { theme: 'colored' });
    } finally {
      setLoading(false);
    }
  }

  const getPortfolioMetrics = async () => {
    try {
      setLoadingPortfolioMetrics(true);
      const response = await vacaygreen.pms.getPortfolioMetrics(user.mainAccount.id);
      const data = await response.json();
      setPortfolioCoverage({
        propertiesCovered: data.offsetEnabledProperties,
        totalProperties: data.offsetEnabledProperties + data.offsetDisabledProperties + data.offsetSuspendedProperties
      })
    } catch (error) {
      toast.error(`Something went calculating your metrics, please try again later`, { theme: 'colored' });
    } finally {
      setLoadingPortfolioMetrics(false);
    }
  }

  useEffect(() => {
    getSettings();
  }, [])

  useEffect(() => {
    loadFinancialMetrics();
  }, [settings])


  useEffect(() => {
    loadAccountMetrics();
    getPortfolioMetrics();
  }, [refresh])

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <LastUpdated lastUpdate={accountMetrics.lastUpdate} onRefresh={() => updateAccountMetrics()} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <StatisticsCard
            loading={loading}
            stat={rnrStats}
            style={{ minWidth: '12rem' }}
          />
        </Col>
        <Col>
          <StatisticsCard
            loading={loading}
            stat={incomeStats}
            style={{ minWidth: '12rem' }}
          />
        </Col>
        <Col>
          <StatisticsCard
            loading={loading}
            stat={co2Stats}
            style={{ minWidth: '12rem' }}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <StatisticsGridCard title={getTreeMetricsTitle(treeMetrics)} data={transforTreeMetrics(treeMetrics)} />
        </Col>
      </Row>
      {financialMetrics &&
        <Row className="g-3 mb-3">
          <Col>
            <FinancialChart chartData={financialMetrics} />
          </Col>
        </Row>
      }
      {kpiMetrics &&
        <Row className="g-3 mb-3">
          <Col>
            <MiniStatisticsCard stat={kpiMetrics.targetMargin} />
          </Col>
          <Col>
            <MiniStatisticsCard stat={kpiMetrics.actualMargin} />
          </Col>
          <Col>
            <MiniStatisticsCard stat={kpiMetrics.estimatedOccupancyRate} />
          </Col>
          <Col>
            <MiniStatisticsCard stat={kpiMetrics.actualOccupancyRate} />
          </Col>
        </Row>
      }
      {rnfMetrics &&
        <Row className="g-3 mb-3">
          <Col>
            <ReservationNightRateChart chartData={rnfMetrics} />
          </Col>
        </Row>
      }
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <PropertiesTable tableData={topPerformersMetrics} />
        </Col>
        <Col xxl={3}>
          <Row className="g-0 mb-3">
            {loadingPortfolioMetrics ? <Loader /> : <GaugeChartCard portfolioCoverage={portfolioCoverage} />}
          </Row>
          <Row className="g-0 mb-3">
            {loading ? <Loader /> : <PmsSyncStats data={propertySyncs} title="PMS Sync Status" />}
          </Row>
          <Row className="g-0 mb-3">
            {loading ? <Loader /> : <PmsSyncStats data={reservationSyncs} title="Reservations Sync Status" />}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PropertyManagerDashboard;
