/* eslint-disable react-hooks/exhaustive-deps */
import { AuthContext } from 'context/auth/AuthContext';
import { OnboardingContext } from 'context/onboarding/OnboardingContext';
import React, { useContext, useEffect, useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const DataSyncForm = ({ onSyncCompleted, onSyncFailed }) => {

  const { setNextDisabled, currentTicket, setCurrentSyncTicket, clearCurrentSyncTicket, setBackDisabled } = useContext(OnboardingContext);
  const vacaygreen = useVacaygreen();
  const { account } = useContext(AuthContext);

  const [ticket, setTicket] = useState();
  const [timer, setTimer] = useState();

  useEffect(() => {
    setNextDisabled(true);
  }, [])

  const startPollingTicket = async () => {
    try {
      if (!ticket && !currentTicket) return;

      const response = await vacaygreen.sync.getTicket(account, currentTicket || ticket.id);
      if (response.ok) {
        const newTicket = await response.json();
        if (newTicket.result === "ERROR") {
          stopPollingTicket();
          onSyncFailed && onSyncFailed();
        } else
          setTicket(newTicket);
      }
    } catch (e) {
      console.error(e)
    }
  }

  const stopPollingTicket = async () => {
    clearTimeout(timer)
    clearCurrentSyncTicket()
    setTicket(null);
    setBackDisabled(false)
  }

  const startPropertySync = async () => {
    try {
      const response = await vacaygreen.sync.startPropertiesSyncForAccount(account);
      if (response.ok) {
        const newTicket = await response.json();
        setTicket(newTicket);
        setBackDisabled(true)
        setCurrentSyncTicket(newTicket.id);
      }
    } catch (e) {
      console.error(e)
    } finally {

    }
  }

  useEffect(() => {
    const percentage = ticket?.completionPercentage || 0;
    if (percentage < 100) {
      setTimer(setTimeout(startPollingTicket, 1000));
      if (percentage > 0) setBackDisabled(true)
    } else {
      stopPollingTicket();
      onSyncCompleted && onSyncCompleted();
    }
  }, [ticket, currentTicket])

  return (
    <>
      <h3>
        Time to import your portfolio
      </h3>
      {(ticket || currentTicket) ? (
        <div>
          <p>Data syncronization in progress, please wait a moment for it to complete.</p>
          <ProgressBar className='mt-3' min={0} max={100} now={ticket?.completionPercentage || 0} animated={true} variant="warning" />
        </div>
      ) : (
        <div>
          <p>Click the button below when you're ready to start synchrizing your data.</p>
          <Button variant="primary" onClick={startPropertySync}>Start Now!</Button>
        </div>
      )}
    </>
  );
};

export default DataSyncForm;
