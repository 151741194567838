import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import classNames from 'classnames';

import Flex from 'components/common/Flex';
import { getColor, numberFormatter } from 'helpers/utils';

echarts.use([GaugeChart, CanvasRenderer]);

const getOptions = data => ({
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: getColor('success')
        }
      },
      axisLine: {
        lineStyle: {
          width: 8,
          color: [[1, getColor('200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: numberFormatter(data),
          detail: {
            offsetCenter: ['7%', '4%']
          }
        },
      ],
      detail: {
        width: 50,
        height: 14,
        fontSize: 28,
        fontWeight: 500,
        fontFamily: 'poppins',
        color: getColor('500'),
        formatter: '{value}%'
      }
    }
  ]
});

const GaugeChartCard = ({ portfolioCoverage = {}, bodyClassName }) => {
  const { propertiesCovered, totalProperties } = portfolioCoverage;
  return (
    <Card className="h-100">
      <Card.Body
        className={classNames(bodyClassName, 'h-100')}
        as={Flex}
        direction="column"
        alignItems="between"
      >
        <h6 className="mb-md-0 mb-lg-0 mb-xl-3">Portfolio Coverage</h6>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions((propertiesCovered / totalProperties) * 100)}
          style={{ height: '12rem' }}
        />
        <div className="text-center mt-3">
          <h6 className="fs-0 mb-1">
            <FontAwesomeIcon
              icon="check"
              transform="shrink-2"
              className="text-success me-1"
            />
            {propertiesCovered}/{totalProperties} properties covered
          </h6>
        </div>
      </Card.Body>
    </Card>
  );
};

GaugeChartCard.propTypes = {
  bodyClassName: PropTypes.string
};

export default GaugeChartCard;
