import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WizardInput from '../wizard/WizardInput';
import Avatar from 'components/common/Avatar';
import avatarImg from 'assets/img/team/avatar.png';
import { isIterableArray } from 'helpers/utils';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import FalconDropzone from 'components/common/FalconDropzone';
import Flex from 'components/common/Flex';
import { OnboardingContext } from 'context/onboarding/OnboardingContext';

const AccountInfoForm = ({ register, errors, setValue }) => {
  const { accountInfo, setNextDisabled, step } = useContext(OnboardingContext);
  const [avatar, setAvatar] = useState([
    ...(accountInfo.logo ? accountInfo.logo : []),
    { src: avatarImg }
  ]);

  useEffect(() => {
    if(step == 1)
      setNextDisabled(false)
  }, [step])

  return (
    <>
      <h3>
        Hi there! Welcome to VacayGreen 👋
      </h3>
      <p>
        Please let us know a bit more about you by filling your info below.
      </p>
      <WizardInput
        label="Company Name"
        name="companyName"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('companyName',{
            required:'Please use a name for your account'
          })
        }}
      />

      <Row className="mb-3">
        <Col md="auto">
          <Avatar
            size="4xl"
            src={
              isIterableArray(avatar) ? avatar[0]?.base64 || avatar[0]?.src : ''
            }
          />
        </Col>
        <Col md>
          <FalconDropzone
            files={avatar}
            onChange={files => {
              setAvatar(files);
              setValue('logo', files);
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <>
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">
                    Upload your company logo or profile picture
                  </p>
                </Flex>
                <p className="mb-0 w-75 mx-auto text-400">
                  Upload a 300x300 jpg image with a maximum size of 400KB
                </p>
              </>
            }
          />
        </Col>
      </Row>

      <WizardInput
        errors={errors}
        label="Address Line 1"
        name="address1"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('address1', {
            required: 'Address line 1 is required'
          })
        }}
      />

      <WizardInput
        errors={errors}
        label="Address Line 2 (optional)"
        name="address2"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('address2')
        }}
      />

      <Row className="g-2 mb-3">
        <WizardInput
          errors={errors}
          label="City"
          name="city"
          formGroupProps={{ as: Col, sm: 4 }}
          formControlProps={{
            ...register('city', {
              required: 'You must specify a city'
            })
          }}
        />
        <WizardInput
          errors={errors}
          label="State"
          name="state"
          formGroupProps={{ as: Col, sm: 4 }}
          formControlProps={{
            ...register('state', {
              required: 'You must specify a state'
            })
          }}
        />
        <WizardInput
          errors={errors}
          label="Zip"
          name="zipCode"
          formGroupProps={{ as: Col, sm: 4 }}
          formControlProps={{
            ...register('zipCode', {
              required: 'You must specify a zip code'
            })
          }}
        />
      </Row>
      <WizardInput
        label="How did you hear about us?"
        name="source"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('source')
        }}
      />
      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            I accept the <a target="_blank" rel='noreferrer' href="https://www.vacaygreen.com/terms-and-conditions"> terms</a> and{' '}
            <a target="_blank" rel='noreferrer' href="https://www.vacaygreen.com/privacy-policy"> privacy policy</a>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'You need to agree the terms and privacy policy.'
          })
        }}
      />
    </>
  );
};

AccountInfoForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default AccountInfoForm;
