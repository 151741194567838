import React from 'react'
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AccountsTableHeader from './AccountsTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';

const defaultColumns = [
  {
    accessor: 'id',
    Header: 'Id',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <>
          <Link to="/e-commerce/orders/order-details">
            <strong>{id}</strong>
          </Link>{' '}
        </>
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Company Name',
    headerProps: { className: 'pe-7' },
    Cell: rowData => {
      const { name, id } = rowData.row.original;

      return (
        <>
          <Link to={`/admin/accounts/${id}`}>
            <strong>{name || 'Not set'}</strong> <br />
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'pms.name',
    Header: 'PMS',
    Cell: rowData => {
      const { pms } = rowData.row.original;

      return (
        <>
          <strong>{pms?.name || 'Not set'}</strong> <br />
        </>
      );
    }
  },
  {
    accessor: 'state',
    Header: 'Status',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'fs-0 text-end'
    },
    Cell: rowData => {
      const { state } = rowData.row.original;
      return (
        <SoftBadge
          pill
          bg={classNames({
            success: state === 'ACTIVE',
            primary: state === 'NEW',
            warning: state === 'INACTIVE',
            danger: state === 'SUSPENDED'
          })}
          className="px-3"
        >
          {state === 'ACTIVE' && 'ACTIVE'}
          {state === 'NEW' && 'NEW'}
          {state === 'INACTIVE' && 'INACTIVE'}
          {state === 'SUSPENDED' && 'SUSPENDED'}
          <FontAwesomeIcon
            icon={classNames({
              check: state === 'ACTIVE',
              ban: state === 'SUSPENDED'
            })}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    }
  }
];

const AccountList = ({ accounts, columns, pagination }) => {
  const { currentPage, maxResults, pageSize, onChangePage } = pagination;
  return (
    <AdvanceTableWrapper
      columns={columns || defaultColumns}
      data={accounts}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <AccountsTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination
            canPreviousPage={currentPage !== 0}
            canNextPage={currentPage !== Math.floor(maxResults / pageSize)}
            previousPage={() => onChangePage(currentPage - 1)}
            nextPage={() => onChangePage(currentPage + 1)}
            pageCount={Math.ceil(maxResults / pageSize)}
            pageIndex={currentPage}
            gotoPage={(page) => onChangePage(page)}
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default AccountList