import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import { toast } from 'react-toastify';
import PMSDropdown from 'components/pms/PMSDropdown';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'context/auth/AuthContext';
import DataSyncForm from 'components/onboarding/DataSyncForm';
import { OnboardingContext } from 'context/onboarding/OnboardingContext';
import Success from 'components/wizard/Success';

const AccountSettings = () => {
  const vacaygreen = useVacaygreen();
  const [pmss, setPmss] = useState([]);
  const { currentTicket } = useContext(OnboardingContext);
  const { user, account, setUserMainAccountState } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [syncError, setSyncError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    defaultValues: {
      "pms": user.mainAccount.pms?.pms
    }
  });

  const getPMSProviders = async () => {
    try {
      const response = await vacaygreen.onboarding.getPMSProviders();
      if (response.ok) {
        const data = await response.json();
        setPmss(data);
      }
    } catch (error) {
      toast.error(`Something went wrong loading PMS configuration, try again later`, { theme: 'colored' });
    }
  }

  const onSubmitData = async data => {
    try {
      if (data.pms !== "NONE") {
        const response = await vacaygreen.onboarding.setPMS(account, data);
        if (response.ok) {
          setStep(2);
          setUserMainAccountState({ ...user.mainAccount, pms: data })
          toast.success(`PMS successfully updated!`, { theme: 'colored' });
        }
      }
    } catch (error) {
      console.error(error)
      toast.error(`Something went wrong updating PMS configuration, try again later`, { theme: 'colored' });
    }
  };

  const onSyncCompleted = () => {
    setStep(3);
  }

  const onSyncFailed = () => {
    setStep(1);
    setSyncError(true);
  }

  useEffect(() => {
    getPMSProviders();
  }, [])

  useEffect(() => {
    if (currentTicket) setStep(2);
  }, [currentTicket])

  return (
    <Card
      as={Form}
      noValidate
      onSubmit={handleSubmit(onSubmitData)}
      className="mb-3"
    >
      <FalconCardHeader title="Account Settings" />
      <Card.Body className="bg-light">
        {syncError && <div className="alert alert-danger" role="alert">The property synchronization failed, please make sure the configuration you provided is ok.<br/><br/> Please <a href="mailto:support@vacaygreen.com">contact support</a> otherwise.</div>}
        {step === 1 &&
          <div>
            <PMSDropdown
              register={register}
              errors={errors}
              watch={watch}
              pmss={pmss}
            />

            <div className="border-dashed-bottom my-3" />

            <div className="text-end">
              <Button variant="primary" type="submit">
                Update
              </Button>
            </div>
          </div>
        }
        {step === 2 &&
          <DataSyncForm onSyncCompleted={() => onSyncCompleted()} onSyncFailed={() => onSyncFailed()} />
        }
        {step === 3 && <Success reset={reset} hideAnimation={true} />}
      </Card.Body>
    </Card>
  );
};

export default AccountSettings;
