import React from 'react';
import PageHeader from 'components/common/PageHeader';

import SoftBadge from 'components/common/SoftBadge';
import { Card, Col, Row } from 'react-bootstrap';
import AllocationsList from 'components/allocations/AllocationsList';


const PropertyLocation = ({ property }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Address</h5>
            <p className="mb-1 fs--1">
              {property.address}
            </p>
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Traits</h5>
            <p className="mb-0 fs--1">
              <strong>
                Bedrooms: {property.bedrooms}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Bathrooms: {property.bathrooms}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Sleeps: {property.sleeps}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Size: {property.sqft}
              </strong>
            </p>
          </Col>
          <Col md={6} lg={4}>
            <h5 className="mb-3 fs-0">Usage</h5>
            <p className="mb-0 fs--1">
              <strong>
                Emissions Per Day: {property.emissionsPerDay}
              </strong>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const PropertyDetails = ({ property, transactions, pagination }) => {
  if (!property || !transactions) return null;
  return (
    <>
      <PageHeader title={`Property ${property.code} - ${property.name}`} titleTag="h5" className="mb-3">
        <p className="fs--1 mt-1">External ref {property.externalId}</p>
        <div>
          <SoftBadge pill bg={property.active ? "success" : "secondary"} className="fs--2 mx-2">
            {property.active ? "ACTIVE" : "INACTIVE"}
          </SoftBadge>
          <SoftBadge pill bg={property.offsetStatus === "ENABLED" ? "success" : property.offsetStatus === "DISABLED" ? "secondary" : "danger"} className="fs--2 mx-2">
            {property.offsetStatus}
          </SoftBadge>
          <SoftBadge pill bg={property.occupancy === "OCCUPIED" ? "success" : "secondary"} className="fs--2 mx-2">
            {property.occupancy === "OCCUPIED" ? "OCCUPIED" : "VACANT"}
          </SoftBadge>
        </div>
      </PageHeader>
      <PropertyLocation property={property} />
      {transactions &&
        <AllocationsList
          allocations={transactions}
          pagination={pagination}
        />
      }
    </>
  );
};

export default PropertyDetails;
