import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Button, Form } from 'react-bootstrap';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import { AuthContext } from 'context/auth/AuthContext';
import Loader from 'components/common/Loader';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const vacaygreen = useVacaygreen()

  // Handler
  const handleSubmit = async e => {

    e.preventDefault();
    setError(null);
    try
    {
      setLoading(true);
      const response = await vacaygreen.auth.forgot(email);
      if(response.ok)
        setSuccess(true);
      else{
       const errorResponse = await response.json();
       throw errorResponse.errorMessage;
      }
    }catch(e){
      console.log(e);
      setError("Couldn't send instructions to the provided email address");
    }finally{
      setLoading(false);
    }
  };


  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">We have sent instructions on how to recover the password to the provided email address.</Alert>}
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {loading ? 
        <Loader /> : <Button className="w-100" type="submit" disabled={!email}>
          Send reset link
        </Button>}
      </Form.Group>

      <Link className="fs--1 text-600" to="#!">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
