import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart, BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs';
import { Card, Form } from 'react-bootstrap';

import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { getColor } from 'helpers/utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  BarChart
]);

const LABEL_FONT_WEIGHT = 'lighter';
const aggregateDataByOptions = ['Quarterly', 'Monthly'];
const timePeriodFilterOptions = ['YTD', 'Annual'];

// For future dates we will use the 'next' data set (e.g., for Projected Revenue, use params[2] for future dates because params[1] is null for future dates)
// Note that the data is dereferenced differently if getDataWithoutLabelCollisions() is called (e.g., Projected Revenue vs Net Income)
const tooltipFormatter = params => {
  return `<div class="card">
              <div class="card-header bg-300 py-2">
                <h6 class="text-600 mb-0">${params[0]?.axisValue}</h6>
              </div>
            <div class="card-body py-2">
              <h6 class="text-600 mb-0 fw-normal">
                <span class="dot me-1 d-inline-block bg-300"></span>
                Carbon Offset Cost: 
                <span class="fw-medium">$${params[0]?.data.value}</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-warning"></span>
                Projected Revenue: 
                <span class="fw-medium">$${
                  !params[1]?.data?.value
                    ? params[2]?.data?.value
                    : params[1]?.data?.value
                }</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-danger"></span>
                Actual Revenue: 
                <span class="fw-medium">$${
                  !params[3]?.data?.value
                    ? params[4]?.data?.value
                    : params[3]?.data?.value
                }</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-info"></span>
                Net Income: 
                <span class="fw-medium">$${
                  !params[5]?.data ? params[6]?.data : params[5]?.data
                }</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-success"></span>
                Net Income (cumulative): 
                <span class="fw-medium">$${
                  !params[7]?.data ? params[8]?.data : params[7]?.data
                }</span>
              </h6>
            </div>
          </div>`;
};

// Bars for future dates should be lighter.
const getBarGraphOpacity = (index, dates) => {
  return new Date() > new Date(dates[index]) ? 1.0 : 0.35;
};

const getBarChartData = (dates, barChartData) => {
  return barChartData.map((dataPoint, index) => ({
    value: getFormattedCurrency(dataPoint),
    itemStyle: {
      borderRadius: [4, 4, 0, 0],
      color: getColor('300'),
      opacity: getBarGraphOpacity(index, dates)
    }
  }));
};

// Unfortunately, eCharts doesn't do anything to avoid label collisions, so we have to build it ourselves.
// This method will only detect collisions between 2 datasets.
const getDataWithoutLabelCollisions = (
  thisDataSet,
  otherDataSet,
  color,
  hideFirstLabel = false
) => {
  return thisDataSet.map((thisDataPoint, index) => {
    if (index === 0 && hideFirstLabel) {
      return;
    }
    const otherDataPoint = otherDataSet[index];
    const labelPosition = thisDataPoint > otherDataPoint ? 'top' : 'bottom';
    return {
      value: getFormattedCurrency(thisDataPoint),
      label: {
        show: 'true',
        position: otherDataPoint === thisDataPoint ? null : labelPosition,
        color: color,
        fontWeight: LABEL_FONT_WEIGHT
      }
    };
  });
};

const getFormattedCurrency = number => {
  if (number === null) {
    return null;
  }

  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  });
  return formatter.format(number);
};

const getXAxisValues = (
  aggregateByOptionIndex,
  timePeriodFilterOptionIndex,
  datesData
) => {
  if (aggregateByOptionIndex === '0') {
    const year = dayjs(datesData[0]).year(); // Unfortunately, dayjs does not support formatting quarters.
    return timePeriodFilterOptionIndex === '0'
      ? [`Q1 ${year}`, `Q2 ${year}`, `Q3 ${year}`]
      : [`Q1 ${year}`, `Q2 ${year}`, `Q3 ${year}`, `Q4 ${year}`];
  } else if (aggregateByOptionIndex === '1') {
    return datesData.map(date => dayjs(date).format('MMM'));
  } else {
    throw new 'Unknown aggregateByOption: '() + aggregateByOptionIndex;
  }
};

const getOptionsWithSelectedChartData = (
  aggregateByOptionIndex,
  timePeriodFilterOptionIndex,
  chartData
) => {
  let aggregatedChartData = {};

  // YTD, by quarter
  if (timePeriodFilterOptionIndex === '0' && aggregateByOptionIndex === '0') {
    aggregatedChartData = chartData.ytdDataByQuarter;
  }
  // YTD, by month
  else if (
    timePeriodFilterOptionIndex === '0' &&
    aggregateByOptionIndex === '1'
  ) {
    aggregatedChartData = chartData.ytdDataByMonth;
  }
  // Annual, by quarter
  else if (
    timePeriodFilterOptionIndex === '1' &&
    aggregateByOptionIndex === '0'
  ) {
    aggregatedChartData = chartData.annualDataByQuarter;
  }
  // Annual, by month
  else if (
    timePeriodFilterOptionIndex === '1' &&
    aggregateByOptionIndex === '1'
  ) {
    aggregatedChartData = chartData.annualDataByMonth;
  }
  // Unexpected combination
  else {
    throw new `Unexpected time period filter & aggregate by combination: ${timePeriodFilterOptionIndex} & ${aggregateByOptionIndex}`();
  }

  return getOptions(
    getXAxisValues(
      aggregateByOptionIndex,
      timePeriodFilterOptionIndex,
      aggregatedChartData.dates
    ),
    aggregatedChartData.dates,
    aggregatedChartData.carbonOffsetCost,
    aggregatedChartData.projectedRevenue,
    aggregatedChartData.projectedRevenueFuture,
    aggregatedChartData.actualRevenue,
    aggregatedChartData.actualRevenueFuture,
    aggregatedChartData.netIncome,
    aggregatedChartData.netIncomeFuture,
    aggregatedChartData.netIncomeCumulative,
    aggregatedChartData.netIncomeCumulativeFuture
  );
};

const getOptions = (
  xAxisData,
  datesData,
  carbonOffsetCostData,
  projectedRevenueData,
  projectedRevenueFutureData,
  actualRevenueData,
  actualRevenueFutureData,
  netIncomeData,
  netIncomeFutureData,
  netIncomeCumulativeData,
  netIncomeCumulativeFutureData
) => ({
  legend: {
    left: 'right',
    data: [
      'Carbon Offset Cost',
      'Projected Revenue',
      'Actual Revenue',
      'Net Income',
      'Net Income (cumulative)'
    ],
    textStyle: { color: getColor('600') }
  },
  axisPointer: {
    show: true
  },
  tooltip: {
    trigger: 'axis',
    padding: 0,
    backgroundColor: 'transparent',
    borderWidth: 0,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  xAxis: {
    type: 'category',
    data: xAxisData,
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: { color: getColor('600') },
    boundaryGap: true
  },
  yAxis: {
    type: 'value',
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      show: true,
      color: getColor('400'),
      formatter: '${value}'
    },
    boundaryGap: false,
    splitLine: {
      show: true,
      lineStyle: { color: getColor('200') }
    }
  },
  series: [
    {
      name: 'Carbon Offset Cost',
      data: getBarChartData(datesData, carbonOffsetCostData),
      type: 'bar',
      itemStyle: {
        color: getColor('300')
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '${c}',
        color: getColor('600')
      },
      emphasis: {
        itemStyle: {
          color: getColor('400'),
          opacity: 1
        }
      }
    },
    {
      name: 'Projected Revenue',
      data: getDataWithoutLabelCollisions(
        projectedRevenueData,
        actualRevenueData,
        getColor('warning')
      ),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('warning'),
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('warning'),
        borderWidth: 2
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '${c}'
      }
    },
    {
      name: 'Projected Revenue Future',
      data: getDataWithoutLabelCollisions(
        projectedRevenueFutureData,
        actualRevenueFutureData,
        getColor('warning'),
        true
      ),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('warning'),
        type: 'dashed',
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('warning'),
        borderWidth: 2
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '${c}'
      }
    },
    {
      name: 'Actual Revenue',
      data: getDataWithoutLabelCollisions(
        actualRevenueData,
        projectedRevenueData,
        getColor('danger')
      ),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('danger'),
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('danger'),
        borderWidth: 2
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '${c}'
      }
    },
    {
      name: 'Actual Revenue Future',
      data: getDataWithoutLabelCollisions(
        actualRevenueFutureData,
        projectedRevenueFutureData,
        getColor('danger'),
        true
      ),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('danger'),
        type: 'dashed',
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('danger'),
        borderWidth: 2
      },
      label: {
        show: true,
        fontWeight: LABEL_FONT_WEIGHT,
        formatter: '${c}'
      }
    },
    {
      name: 'Net Income',
      data: netIncomeData.map(number => getFormattedCurrency(number)),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('info'),
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('info'),
        borderWidth: 2
      },
      label: {
        show: true,
        formatter: '${c}',
        fontWeight: LABEL_FONT_WEIGHT,
        color: getColor('info')
      }
    },
    {
      name: 'Net Income Future',
      data: netIncomeFutureData.map(number => getFormattedCurrency(number)),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('info'),
        type: 'dashed',
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('info'),
        borderWidth: 2
      },
      label: {
        show: true,
        formatter: '${c}',
        fontWeight: LABEL_FONT_WEIGHT,
        color: getColor('info')
      }
    },
    {
      name: 'Net Income (cumulative)',
      data: netIncomeCumulativeData.map(number => getFormattedCurrency(number)),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('success'),
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('success'),
        borderWidth: 2
      },
      label: {
        show: true,
        formatter: '${c}',
        fontWeight: LABEL_FONT_WEIGHT,
        color: getColor('success')
      }
    },
    {
      name: 'Net Income (cumulative) Future',
      data: netIncomeCumulativeFutureData.map(number =>
        getFormattedCurrency(number)
      ),
      type: 'line',
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        color: getColor('success'),
        type: 'dashed',
        width: 2
      },
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('success'),
        borderWidth: 2
      },
      label: {
        show: true,
        formatter: '${c}',
        fontWeight: LABEL_FONT_WEIGHT,
        color: getColor('success')
      }
    }
  ],
  grid: { right: '1%', left: '5%', bottom: '5%', top: '5%' }
});

const FinancialChart = ({ chartData }) => {
  const [aggregateByOptionIndex, setAggregateByOptionIndex] = useState('0');
  const [timePeriodFilterOptionIndex, setTimePeriodFilterOptionIndex] =
    useState('0');
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Financial Performance"
        titleTag="h3"
        className="pb-0"
        endEl={
          <Flex>
            {/* Time Period Filter Dropdown */}
            <Form.Select
              size="sm"
              style={{ width: 'auto' }}
              value={timePeriodFilterOptionIndex}
              onChange={e => setTimePeriodFilterOptionIndex(e.target.value)}
              className="me-2"
            >
              {timePeriodFilterOptions.map((timePeriodFilterOption, index) => (
                <option value={index} key={timePeriodFilterOption}>
                  {timePeriodFilterOption}
                </option>
              ))}
            </Form.Select>
            {/* AggregateBy Dropdown */}
            <Form.Select
              size="sm"
              style={{ width: 'auto' }}
              value={aggregateByOptionIndex}
              onChange={e => setAggregateByOptionIndex(e.target.value)}
              className="me-2"
            >
              {aggregateDataByOptions.map((aggregateByOption, index) => (
                <option value={index} key={aggregateByOption}>
                  {aggregateByOption}
                </option>
              ))}
            </Form.Select>
            {/* Export Button */}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
            </IconButton>
          </Flex>
        }
      />
      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptionsWithSelectedChartData(
            aggregateByOptionIndex,
            timePeriodFilterOptionIndex,
            chartData
          )}
          style={{ height: '40rem' }}
        />
      </Card.Body>
    </Card>
  );
};

FinancialChart.propTypes = {
  chartData: PropTypes.shape({
    ytdDataByMonth: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      carbonOffsetCost: PropTypes.arrayOf(PropTypes.number),
      projectedRevenue: PropTypes.arrayOf(PropTypes.number),
      projectedRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      actualRevenue: PropTypes.arrayOf(PropTypes.number),
      actualRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      netIncome: PropTypes.arrayOf(PropTypes.number),
      netIncomeFuture: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulative: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulativeFuture: PropTypes.arrayOf(PropTypes.number)
    }),
    ytdDataByQuarter: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      carbonOffsetCost: PropTypes.arrayOf(PropTypes.number),
      projectedRevenue: PropTypes.arrayOf(PropTypes.number),
      projectedRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      actualRevenue: PropTypes.arrayOf(PropTypes.number),
      actualRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      netIncome: PropTypes.arrayOf(PropTypes.number),
      netIncomeFuture: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulative: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulativeFuture: PropTypes.arrayOf(PropTypes.number)
    }),
    annualDataByMonth: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      carbonOffsetCost: PropTypes.arrayOf(PropTypes.number),
      projectedRevenue: PropTypes.arrayOf(PropTypes.number),
      projectedRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      actualRevenue: PropTypes.arrayOf(PropTypes.number),
      actualRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      netIncome: PropTypes.arrayOf(PropTypes.number),
      netIncomeFuture: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulative: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulativeFuture: PropTypes.arrayOf(PropTypes.number)
    }),
    annualDataByQuarter: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      carbonOffsetCost: PropTypes.arrayOf(PropTypes.number),
      projectedRevenue: PropTypes.arrayOf(PropTypes.number),
      projectedRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      actualRevenue: PropTypes.arrayOf(PropTypes.number),
      actualRevenueFuture: PropTypes.arrayOf(PropTypes.number),
      netIncome: PropTypes.arrayOf(PropTypes.number),
      netIncomeFuture: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulative: PropTypes.arrayOf(PropTypes.number),
      netIncomeCumulativeFuture: PropTypes.arrayOf(PropTypes.number)
    })
  })
};

export default FinancialChart;
