import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import { AuthContext } from 'context/auth/AuthContext';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';
import Loader from 'components/common/Loader';

const RegistrationForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    isAccepted: false
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const vacaygreen = useVacaygreen()

  // Handler
  const handleSubmit = async e => {

    e.preventDefault();
    setError(null);
    try
    {
      setLoading(true);
      
      const response = await vacaygreen.auth.signup(formData.name, formData.firstName, formData.lastName, formData.email, formData.password, formData.confirmPassword);
      const body = await response.json();
      if(response.ok)
        setSuccess(true);
      else{
        setSuccess(false);
        setError(`Couldn't sign you up ${body.errorMessage}${body.errorDetails ? ', ' + body.errorDetails : ''}`);
      }

    }catch(e){
      console.log(e);
      setError("Couldn't sign you up");
    }finally{
      setLoading(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">Your account was created succesfully! Check your email and follow the instructions to validate it.</Alert>}
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Company Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Company Name' : ''}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>First Name</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'First Name' : ''}
            value={formData.firstName}
            name="firstName"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Last Name</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Last Name' : ''}
            value={formData.lastName}
            name="lastName"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the <a target="_blank" rel='noreferrer' href="https://www.vacaygreen.com/terms-and-conditions">terms</a> and{' '}
            <a target="_blank" rel='noreferrer' href="https://www.vacaygreen.com/privacy-policy">privacy policy</a>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
      {loading ? 
        <Loader /> : <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.name ||
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.isAccepted
          }
        >
          Register
        </Button>}
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
