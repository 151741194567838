import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SoftBadge = ({ bg = 'primary', pill, children, className, tooltip }) => {

  const component = (
    <div
      className={classNames(className, `badge badge-soft-${bg}`, {
        'rounded-pill': pill
      })}
    >
      {children}
    </div>
  )

  return tooltip ? (
    <OverlayTrigger
      overlay={<Tooltip id="previousTooltip">{tooltip}</Tooltip>}
    >
      {component}
    </OverlayTrigger>
  ) : component;
};

SoftBadge.propTypes = {
  bg: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark'
  ]),
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default SoftBadge;
