import React from 'react'

import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom'; import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AllocationsTableHeader from './AllocationsTableHeader';
import { formatDate } from 'utils/TimeUtils';
import { numberFormatter, toCurrency } from 'helpers/utils';

const defaultColumns = [
  {
    accessor: 'id',
    Header: 'Id',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    }
  },
  {
    accessor: 'propertyCode',
    Header: 'Property Code',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { propertyCode, id } = rowData.row.original;
      return (
        <>
          <Link to={`/admin/properties/${id}`}>
            <strong>{propertyCode}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'volume',
    Header: 'Volume',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { volume } = rowData.row.original;
      return <span>{numberFormatter(volume)}</span>;
    }
  },
  {
    accessor: 'cost',
    Header: 'Cost',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { cost } = rowData.row.original;
      return <span>{toCurrency(cost)}</span>;
    }
  },
  {
    accessor: 'state',
    Header: 'Status',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { status, deactivatedOn, billedOn } = rowData.row.original;
      return (
        <SoftBadge
          pill
          bg={classNames({
            success: status === 'ACTIVE',
            primary: status === 'BILLED',
            danger: status === 'INACTIVE'
          })}
          className="px-3"
          tooltip={status === 'BILLED' ? `Billed on ${formatDate(billedOn)}` : status === 'INACTIVE' ? `Inactive since ${formatDate(deactivatedOn)}` : null}
        >
          {status}
        </SoftBadge>
      );
    }
  },
  {
    accessor: 'createdOn',
    Header: 'Created On',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { createdOn } = rowData.row.original;
      return <span>{formatDate(createdOn)}</span>;
    }
  },
];

const AllocationsList = ({ allocations, columns, pagination }) => {
  const { currentPage, maxResults, pageSize, onChangePage } = pagination;
  return (
    <AdvanceTableWrapper
      columns={columns || defaultColumns}
      data={allocations}
      sortable
      pagination
      perPage={100}
    >
      <Card className="mb-3">
        <Card.Header>
          <AllocationsTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination
            canPreviousPage={currentPage !== 0}
            canNextPage={currentPage !== Math.floor(maxResults / pageSize)}
            previousPage={() => onChangePage(currentPage - 1)}
            nextPage={() => onChangePage(currentPage + 1)}
            pageCount={Math.ceil(maxResults / pageSize)}
            pageIndex={currentPage}
            gotoPage={(page) => onChangePage(page)}
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default AllocationsList