import React from 'react';
import { Link } from 'react-router-dom';
import AuthCardLayout from 'layouts/AuthCardLayout';
import RegistrationForm from 'components/authentication/RegistrationForm';
import { Button } from 'react-bootstrap';

const Registration = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="pt-3 text-primary">
          Have an account?
          <br />
          <Link className="fs--1 text-600" to="/authentication/login">
            <span className="d-inline-block ms-1"> &larr;</span> Back to log in
          </Link>
        </p>
      }
      footer={false}
    >
      <h3>Register</h3>
      <RegistrationForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Registration;
