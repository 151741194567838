import { OnboardingProvider } from 'context/onboarding/OnboardingContext';
import React from 'react'
import OnboardingWizardLayout from './OnboardingWizardLayout'

const OnboardingWizard = ({ leftSideContent, children, footer = true }) => {
  return (
      <OnboardingProvider>
        <OnboardingWizardLayout validation={true}>

        </OnboardingWizardLayout>
     </OnboardingProvider>
  )
}

export default OnboardingWizard;