import { authApiFetch } from '../../Vacaygreen'

const VacaygreenPMS = {

    getProperties: async (page, pageSize, offsetStatus) => {
        return authApiFetch(`properties?page=${page || 0}&pageSize=${pageSize || 50}${offsetStatus ? `&carbonNeutralStatus=${offsetStatus}` : ""}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getPropertiesPerformance: async (page, pageSize, offsetStatus) => {
        return authApiFetch(`properties/performance?page=${page || 0}&pageSize=${pageSize || 50}${offsetStatus ? `&carbonNeutralStatus=${offsetStatus}` : ""}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getPortfolioMetrics: async (accountId) => {
        return authApiFetch(`accounts/${accountId}/metrics/portfolio`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    setOffsetStatus: async (propertyId, offsetStatus) => {
        return authApiFetch(`properties/${propertyId}/offset-status/${offsetStatus}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    createProperty: async (property) => {
        return authApiFetch(`properties`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(property)
        });
    },

    enableEntirePortfolio: async (property) => {
        return authApiFetch(`properties/enable`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(property)
        });
    },

    uploadPortfolio: async (file) => {
        return authApiFetch(`properties/import`, {
            method: "POST",
            body: JSON.stringify(file)
        });
    }
}

export default VacaygreenPMS;