import React, { useContext, useState } from 'react';
import { Nav, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/auth/AuthContext';

import Avatar from 'components/common/Avatar';
import empty from 'assets/img/team/avatar.png';
import ConfirmModal from 'components/common/ConfirmModal';

const AccountDropDown = ({ menuItems = [] }) => {
  const { logout, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleLogout = () => {
    logout();
    setConfirmOpen(false);
    navigate("/authentication/logout");
  };

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* <ProfileDropdown /> */}
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          <div>
            <div className="d-inline-block text-end">
              <strong>{user.mainAccount.name}</strong><br />
              <span>{user.firstName} {user.lastName}</span>
            </div>
            <div className="d-inline-block ms-2 text-end">
              <Avatar src={user.mainAccount?.logoImage || empty} />
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            {
              menuItems.map((item, index) => (
                <Dropdown.Item key={index} as={Link} to={item.route}>
                  {item.name}
                </Dropdown.Item>
              ))
            }
            <Dropdown.Item to="#" onClick={() => setConfirmOpen(true)} >
              Logout
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      {confirmOpen &&
        <ConfirmModal
          isOpen={confirmOpen}
          setIsOpen={() => setConfirmOpen(prev => !prev)}
          confirm={handleLogout}
          header='Are you sure'
          body='Are you sure you want to log out?'
          confirmButtonText='Yes'
        />
      }

    </Nav>
  );
};

export default AccountDropDown;
