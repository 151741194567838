import React from 'react'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { formatDate } from 'utils/TimeUtils';
import { numberFormatter } from 'helpers/utils';
import { mapTransactionType } from 'utils/TextMappers';

const defaultClumns = [
  {
    accessor: 'id',
    Header: 'Id',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    }
  },
  {
    accessor: 'createdOn',
    Header: 'Date',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { createdOn } = rowData.row.original;
      return <span>{formatDate(createdOn)}</span>;
    }
  },
  {
    accessor: 'transactionType',
    Header: 'Type',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { transactionType } = rowData.row.original;
      return <span>{mapTransactionType(transactionType)}</span>;
    }
  },
  {
    accessor: 'volume',
    Header: 'Volume',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { volume, transactionType } = rowData.row.original;
      return (
        <>
          <span class={`${transactionType === 'ALLOCATION' ? 'text-danger' : 'text-success'}`}>{numberFormatter(volume)}</span>
        </>
      );
    }
  },
  {
    accessor: 'cost',
    Header: 'Cost',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { cost } = rowData.row.original;
      return <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cost)}</span>;
    }
  },
  {
    accessor: 'source',
    Header: 'Vendor',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    }
  }

];


const TransactionsTable = ({ transactions, columns, pagination }) => {
  const { currentPage, maxResults, pageSize, onChangePage } = pagination;
  return (
    <>
      <AdvanceTableWrapper
        columns={columns || defaultClumns}
        data={transactions}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Transactions</h5>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              canPreviousPage={currentPage !== 0}
              canNextPage={currentPage !== Math.floor(maxResults / pageSize)}
              previousPage={() => onChangePage(currentPage - 1)}
              nextPage={() => onChangePage(currentPage + 1)}
              pageCount={Math.ceil(maxResults / pageSize)}
              pageIndex={currentPage}
              gotoPage={(page) => onChangePage(page)}
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  )
}

export default TransactionsTable;