import { authApiFetch } from '../../Vacaygreen'

const VacaygreenAccount = {
    getInvoices: async (accountId, page, pageSize) => {
        return authApiFetch(`accounts/${accountId}/invoices?page=${page || 0}&pageSize=${pageSize || 50}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getInvoice: async (accountId, id) => {
        return authApiFetch(`accounts/${accountId}/invoices/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getMetrics: async (accountId) => {
        return authApiFetch(`accounts/${accountId}/metrics`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getFinancialMetrics: async (accountId) => {
        return authApiFetch(`accounts/${accountId}/metrics/financial`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    updateMetrics: async (accountId) => {
        return authApiFetch(`accounts/${accountId}/metrics`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },

    getSettings: async (accountId) => {
        return authApiFetch(`accounts/${accountId}/settings`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    
    updateSettings: async (accountId, data) => {
        return authApiFetch(`accounts/${accountId}/settings`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
    },
}

export default VacaygreenAccount;