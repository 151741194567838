import { formatDate } from '@fullcalendar/react';
import classNames from 'classnames';
import CardDropdown from 'components/common/CardDropdown';
import Loader from 'components/common/Loader';
import SoftBadge from 'components/common/SoftBadge';
import InvoicesList from 'components/invoices/invoices-list/InvoicesList';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const AdminInvoices = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const vacaygreen = useVacaygreen();
  const [loading, setLoading] = useState(false);
  const [maxResults, setMaxResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const loadInvoices = async () => {
    try {
      setLoading(true);
      const response = await vacaygreen.admin.getInvoices(currentPage, pageSize);
      const data = await response.json();
      setInvoiceList(data.results);
      setMaxResults(data.maxResults);
    } catch (error) {
      toast.error(`Something went wrong loading invoices: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  const updateInvoice = async (id, status) => {
    try {
      const response = await vacaygreen.admin.updateInvoiceStatus(id, status);
      await response.json();
      toast.success(`Invoice #${id} was succesfully updated!`, {
        theme: 'colored'
      });
      loadInvoices()
    } catch (error) {
      toast.error(`Cannot update invoice #${id}`, {
        theme: 'colored'
      });
    }
  }

  const columns = [
    {
      accessor: 'id',
      Header: 'Id',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <>
            <Link to={`/admin/invoices/${id}`}>
              <strong>{id}</strong>
            </Link>
          </>
        );
      }
    }, {
      accessor: 'createdOn',
      Header: 'Created On',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { createdOn } = rowData.row.original;
        return <span>{formatDate(createdOn)}</span>;
      }
    },
    {
      accessor: 'total',
      Header: 'Total Amount',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { total } = rowData.row.original;
        return <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</span>;
      }
    },
    {
      accessor: 'state',
      Header: 'Status',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { invoiceStatus, deactivatedOn, billedOn } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: invoiceStatus === 'PAID',
              primary: invoiceStatus === 'ISSUED',
              warning: invoiceStatus === 'OVERDUE',
              danger: invoiceStatus === 'CANCELLED'
            })}
            className="px-3"
            tooltip={invoiceStatus === 'BILLED' ? `Billed on ${formatDate(billedOn)}` : invoiceStatus === 'INACTIVE' ? `Inactive since ${formatDate(deactivatedOn)}` : null}
          >
            {invoiceStatus}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'paidOn',
      Header: 'Paid On',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { paidOn } = rowData.row.original;
        return <span>{paidOn && formatDate(paidOn)}</span>;
      }
    },
    {
      accessor: 'cancelledOn',
      Header: 'Cancelled On',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { cancelledOn } = rowData.row.original;
        return <span>{cancelledOn && formatDate(cancelledOn)}</span>;
      }
    }, {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1" style={{ position: "fixed" }}>
            <div className="py-2">
              <Dropdown.Item href="#!" onClick={() => updateInvoice(id, "ISSUED")}>Mark as Issued</Dropdown.Item>
              <Dropdown.Item href="#!" onClick={() => updateInvoice(id, "OVERDUE")}>Mark as Overdue</Dropdown.Item>
              <Dropdown.Item href="#!" onClick={() => updateInvoice(id, "PAID")}>Mark as Paid</Dropdown.Item>
              <div class="dropdown-divider" />
              <Dropdown.Item href="#!" onClick={() => updateInvoice(id, "CANCELLED")}>Cancel</Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  useEffect(() => {
    loadInvoices();
  }, [])

  return (loading ? <Loader /> :
    <InvoicesList
      invoices={invoiceList}
      columns={columns}
      pagination={
        {
          maxResults: maxResults,
          currentPage: currentPage,
          pageSize: pageSize,
          onChangePageSize: (pageSize) => setPageSize(pageSize),
          onChangePage: (page) => setCurrentPage(page),
        }
      }
    />
  )
}

export default AdminInvoices