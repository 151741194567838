import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import AccountSettings from './AccountSettings';
import Divider from 'components/common/Divider';
import ReservationSettings from './ReservationSettings';
import { OnboardingProvider } from 'context/onboarding/OnboardingContext';

const Settings = () => {
  return (
    <>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
          <Divider />
          <ReservationSettings />
          <Divider />
          <OnboardingProvider>
            <AccountSettings />
          </OnboardingProvider>
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">

          </div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
