import React, { useContext, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { AuthContext } from 'context/auth/AuthContext';
import FalconDropzone from 'components/common/FalconDropzone';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useVacaygreen } from 'services/vacaygreen/Vacaygreen';

const ProfileSettings = () => {
  const { user, account, setUserProfile } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false);
  const vacaygreen = useVacaygreen();

  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: '+44098098304',
    companyName: user.mainAccount.name,
    logoBase64: user.mainAccount.logoImage,
    address1: user.mainAccount.address?.street,
    city: user.mainAccount.address?.city,
    state: user.mainAccount.address?.state,
    zipCode: user.mainAccount.address?.zipCode
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAvatarChange = avatar => {
    console.log(avatar[0])
    setFormData({
      ...formData,
      logoBase64: avatar[0].base64
    });
  };

  const handleSubmit = async e => {
    setLoading(true)
    e.preventDefault();
    try {
      if (e.currentTarget.checkValidity()) {
        const response = await vacaygreen.onboarding.setAccountInfo(account, formData)
        const data = await response.json();
        setUserProfile(data);
        setValidated(true);
      } else {
        e.stopPropagation()
      }
    } finally {
      setLoading(false)
    }
  };

  return (
    <Card>
      <FalconCardHeader title="Profile Settings" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Row className="mb-3">
            <Col md="auto">
              <Avatar
                size="4xl"
                src={
                  formData.logoBase64
                }
              />
            </Col>
            <Col md>
              <FalconDropzone
                files={formData.logoBase64}
                onChange={files => {
                  handleAvatarChange(files);
                }}
                multiple={false}
                accept="image/*"
                placeholder={
                  <>
                    <Flex justifyContent="center">
                      <img src={cloudUpload} alt="" width={25} className="me-2" />
                      <p className="fs-0 mb-0 text-700">
                        Upload your company logo or profile picture
                      </p>
                    </Flex>
                    <p className="mb-0 w-75 mx-auto text-400">
                      Upload a 300x300 jpg image with a maximum size of 400KB
                    </p>
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Email</Form.Label>
              <p><strong>{formData.email}</strong></p>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="heading">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Company Name"
              value={formData.companyName}
              name="companyName"
              onChange={handleChange}
              isInvalid={formData.companyName === ""}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please use a name for your account
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label><strong>Main Contact</strong></Form.Label>
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                name="firstName"
                onChange={handleChange}
                isInvalid={formData.firstName === ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the first name of the main contact
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                name="lastName"
                onChange={handleChange}
                isInvalid={formData.lastName === ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the last name of the main contact
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="street">
              <Form.Label>Street</Form.Label>
              <Form.Control
                type="text"
                placeholder="Street"
                value={formData.address1}
                name="address1"
                onChange={handleChange}
                isInvalid={formData.address1 === ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                Street address is required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={4} controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                value={formData.city}
                name="city"
                onChange={handleChange}
                isInvalid={formData.city === ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                You must specify a city
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                value={formData.state}
                name="state"
                onChange={handleChange}
                isInvalid={formData.state === ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                You must specify a state
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="zipCode">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip Code"
                value={formData.zipCode}
                name="zipCode"
                onChange={handleChange}
                isInvalid={formData.zipCode === ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                You must specify a zip code
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <div className="text-end">
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? '...' : 'Update'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
