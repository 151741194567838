import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Statistics from './Statistics';
import ProjectTable from './ProjectTable';
import IconButton from 'components/common/IconButton';
import CreatePurchaseForm from './CreatePurchaseForm';

const InventoryMetrics = ({ metrics, projectsTable, projectUsers, onNewTransaction }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  return (
    <Card className="h-100 mb-3">
      <FalconCardHeader
        title="Carbon Credits Inventory"
        titleTag="h6"
        endEl={
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            className="ms-2"
            onClick={() => setShowCreateModal(true)}
          >
            <span className="d-none d-sm-inline-block ms-1">New Purchase</span>
          </IconButton>
        }
      />
      <Card.Body className="pt-0">
        <Statistics data={metrics} />

        {/* NOT YET IMPLEMENTED IN BE 
        <p className="fs--1 mb-2 mt-3">Top consumers</p>
        <AvatarGroup dense>
          {projectUsers.map(({ img, name, id }) => {
            return (
              <Avatar
                src={img && img}
                key={id}
                name={name && name}
                isExact
                size="2xl"
                className="border border-3 rounded-circle border-light"
              />
            );
          })}
        </AvatarGroup> */}

        <ProjectTable data={projectsTable} />

        <Modal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">New Purchase Transaction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreatePurchaseForm
              onSave={
                () => {
                  onNewTransaction && onNewTransaction();
                  setShowCreateModal(false);
                }
              }
            />
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card >
  );
};

InventoryMetrics.propTypes = {
  progressBar: PropTypes.array.isRequired,
  projectsTable: PropTypes.array.isRequired,
  projectUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      img: PropTypes.string,
      name: PropTypes.string
    })
  )
};

export default InventoryMetrics;
